import React, { useEffect, useState } from 'react';
import { Button, MenuItem, TextField, Typography, Grid } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorder } from "../../../../utils/utils";

interface Field {
  title: string;
  description: string;
  index: number;
  required: boolean;
  details: {
    options: string[];
  };
  value: string;
}

interface DropdownListProps {
  field: Field;
  setField: (field: Field) => void;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  fieldNumber: number;
}

const DropdownList: React.FC<DropdownListProps> = (props) => {
  const { field, setField, selected, answerable, formDisabled, fieldNumber } = props;
  const { title, description, index, required, details } = field;
  const [newOption, setNewOption] = useState<string>('');
  const [addedNew, setAddedNew] = useState<boolean>(false);

  // Field extra details
  const { options } = details;

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    setField({
      ...field,
      details: {
        ...details,
        options: reorder(
          options,
          result.source.index,
          result.destination.index
        ),
      },
    });
  }

  const setOptions = (newOptions: string[]) => {
    setField({
      ...field,
      details: {
        ...details,
        options: newOptions,
      },
    });
  }

  const changeOption = (e: any, index: number) => {
    const value = e.target.value;
    let newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  }

  const deleteOption = (index: number) => {
    let newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  }

  const changeNewOption = (e: any) => {
    const value = e.target.value;
    setNewOption(value);
  }

  useEffect(() => {
    if (newOption && newOption !== '') {
      let newOptions = [...options];
      newOptions.push(newOption);
      setOptions(newOptions);
      setNewOption('');
      setAddedNew(true);
    }
    // eslint-disable-next-line
  }, [newOption]);

  useEffect(() => {
    setAddedNew(false);
  }, [selected, answerable]);

  const setValue = (e: any) => {
    const value = e.target.value as string;
    setField({
      ...field,
      value,
    });
  }

  useEffect(() => {
    if (required) {
      setField({
        ...field,
        value: options[0],
      });
    }
    // eslint-disable-next-line
  }, []);

  if ((!selected) || answerable) return (<Grid item container spacing={2}>

    <Grid item xs={12}>
      <Typography variant="h6">{fieldNumber + 1}. {title} {required && <b>*</b>}</Typography>
    </Grid>

    <Grid item xs={12}>
      <TextField
        fullWidth
        id={`dropdown-list-${index}`}
        select
        helperText={`${description}`}
        value={field.value || (required ? '' : options[0])}
        onChange={setValue}
        disabled={formDisabled}
        variant="outlined"
      >
        {!required && <MenuItem value={''}>None</MenuItem>}
        {options.map((option, i) => (
          <MenuItem value={option} key={i}>{option}</MenuItem>
        ))}
      </TextField>
    </Grid>

  </Grid>

  );

  return (
    <Grid item container spacing={2} sx={{marginTop: 1}}>
      <Grid item xs>
        <Typography variant="subtitle1">Options</Typography>
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Grid item container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
              {options.map((option, optionIndex) => {
                const shouldFocus = addedNew && optionIndex === options.length - 1;

                return (
                  <Draggable key={`dropdown-list-${index}-option-${optionIndex}`} draggableId={`dropdown-list-${index}-option-${optionIndex}`} index={optionIndex}>
                    {(provided) => (
                      <Grid item container spacing={2} alignItems={'center'} {...provided.draggableProps} ref={provided.innerRef}>
                        <Grid item xs>
                          <TextField
                            label={`Option ${optionIndex + 1}`}
                            variant="outlined"
                            value={option}
                            onChange={(e) => changeOption(e, optionIndex)}
                            autoFocus={shouldFocus}
                            disabled={formDisabled}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs>
                          <Button variant="contained" color={'error'} onClick={() => deleteOption(optionIndex)}>Delete</Button>
                        </Grid>

                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      <Grid item xs>
        <TextField
          label="New Option"
          variant="outlined"
          value={newOption}
          onChange={changeNewOption}
          style={{ marginBottom: '16px' }}
        />
      </Grid>

    </Grid>
  );
}

export default DropdownList;
