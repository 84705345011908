import React, { useState } from 'react';
import { Typography, Box, ButtonGroup, Button } from '@mui/material';
// import { Bar, Pie } from 'react-chartjs-2';
// import { Chart, registerables } from "chart.js";

interface AnswerSummary {
  label: string;
  value: number;
}

interface Field {
  questionTitle: string;
  answerCount: number;
  answerSummary: AnswerSummary[];
}

interface MultipleChoicesProps {
  field: Field;
  fieldNumber: number;
}

const MultipleChoices: React.FC<MultipleChoicesProps> = ({ field, fieldNumber }) => {
  const { questionTitle, answerCount, answerSummary } = field;
  const [graph, setGraph] = useState<'bar' | 'pie'>('bar');
  const optionsLabels = answerSummary.map(o => o.label);

  const optionsData = answerSummary.map(o => o.value);

 // Chart.register(...registerables)

  const data = {
    labels: optionsLabels,
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context: { dataIndex: string | number; }) {
            return "test"
          }
        }
      },
    },
    datasets: [
      {
        data: optionsData,
        backgroundColor: [
          "#556ee6",
          "#ebeff2",
          "#99e655",
          "#905eca",
        ],
        hoverBackgroundColor: [
          "#556ee6",
          "#ebeff2",
          "#99e655",
          "#905eca",
        ],
        hoverBorderColor: "#fff"
      }]
  };

  const optionsBar = {
    plugins: {
      legend: {
        display: false,
        labels: {
          color: 'rgb(255, 99, 132)'
        }
      }
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 1
        }
      },
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          // @ts-ignore
          callback: function (value: number) {
            // truncate the labels only in this axis
            // @ts-ignore
            const lbl = this.getLabelForValue(value as number);
            if (typeof lbl === 'string' && lbl.length > 10) {
              return `${lbl.substring(0, 10)}...`;
            }
            return lbl;
          },
        },
      },
    }
  };

  const optionsPie = {
    legend: {
      display: true,
      labels: {
        generateLabels: (chart: { config: { data: { labels: any; datasets: { backgroundColor: any; }[]; }; }; }) => {
          const labels = chart.config.data.labels;
          const colors = chart.config.data.datasets[0].backgroundColor;
          return labels.map((l: any, i: number) => {
            return {
              fillStyle: colors[i],
              strokeStyle: colors[i],
              text: ()=>{
                if (typeof l === 'string' && l.length > 10) {
                  return `${l.substring(0, 10)}...`;
                }
                return l;
              }
            };
          });
        }
      }
    }
  };

  return (
    <Box sx={{ minHeight: 440 }}>
      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
        {fieldNumber + 1}. {questionTitle}
      </Typography>
      <Typography variant="body1" mb={1}>
        {answerCount} responses
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: 2 }}>
        <ButtonGroup variant="outlined" color="primary" size="small">
          <Button onClick={() => setGraph('bar')} variant={graph === 'bar' ? 'contained' : 'outlined'}>Bar</Button>
          <Button onClick={() => setGraph('pie')} variant={graph === 'pie' ? 'contained' : 'outlined'}>Pie</Button>
        </ButtonGroup>
      </Box>

      {/*<Box>*/}
      {/*  {graph === 'pie' && <Box p={12}>*/}
      {/*    <Pie width={50}*/}
      {/*         height={20}*/}
      {/*         data={data}*/}
      {/*    // @ts-ignore*/}
      {/*    ptions={optionsPie} /></Box>}*/}
      {/*  {graph === 'bar' &&*/}
      {/*    <Bar width={50}*/}
      {/*     height={20}*/}
      {/*     data={data}*/}
      {/*    // @ts-ignore*/}
      {/*         options={optionsBar} />}*/}
      {/*</Box>*/}
    </Box>
  );
}

export default MultipleChoices;
