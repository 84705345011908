export class AssetType {
  uuid: string = '';
  caption: string = '';
}

export enum VideoDataType {
  ASSET = "ASSET",
  EXTERNAL = "EXTERNAL"
}

export class VideoAssetType {
  data: string = '';
  caption: string = '';
  source: VideoDataType = VideoDataType.ASSET
}
