import {useCallback, useState} from "react";
import * as React from "react";

export type Sort = {
  direction: string,
  by: string
}
export type SortHandler = {
  sort: Sort,
  setSort: React.Dispatch<React.SetStateAction<Sort>>,
  handleSort: (by: string) => void
}

export const useSortHandler = (by = "", direction = "asc"): SortHandler => {
  const [sort, setSort] = useState({ by, direction });

  const handleSort = useCallback((by: string) => {
    setSort((prevState) => {
      if (prevState.by === by) {
        return {
          ...prevState,
          direction: prevState.direction === "desc" ? "asc" : "desc",
        };
      }
      return {
        by,
        direction: "asc",
      };
    });
  }, []);

  return {
    sort,
    setSort,
    handleSort,
  };
};
