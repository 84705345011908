import {useCallback, useState} from "react";

export type ListViewHandler = {
  listView: boolean,
  handleListView: (list: boolean) => void
}

export const useListView = (id: string): ListViewHandler => {
  const [listView, setListView] = useState(JSON.parse(localStorage.getItem(id) || "false"));

  const handleListView = useCallback((list: boolean) => {
    setListView(list);
  }, []);

  return {
    listView,
    handleListView,
  };
};
