import { Box, Card, CardContent, Typography, styled } from "@mui/material";
import React, { FC } from 'react';
import AddIcon from "@mui/icons-material/Add";

interface AddNewFieldProps {
  sectionIndex: number;
  questionIndex: number;
  addNew: (sectionIndex: number, questionIndex: number) => void;
}

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
  boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 6px -1px',
  overflow: 'hidden',
  cursor: 'pointer',
  opacity: 0.4,
  transition: 'opacity 0.3s ease-in-out',
  border: '2px dashed', // Add this for dashed border
  borderColor: theme.palette.primary.light, // Set color for dashed border
  '&:hover': {
    opacity: 1,
  },
  color: theme.palette.primary.light
}));

const AddNewField: FC<AddNewFieldProps> = ({ sectionIndex, questionIndex, addNew }) => {
  return (
    <Box
      sx={{ m: 'auto', pl: 5, pr: 5 }}
      onClick={() => addNew(sectionIndex, questionIndex)}
    >
      <StyledCard>
        <CardContent>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Box sx={{ display: 'flex' }}>
              <AddIcon fontSize='large' />
            </Box>
            <Box sx={{ display: 'flex', ml: 1 }}>
              <Typography variant="h6">
                ADD NEW FIELD
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default AddNewField;
