import React from 'react';
import { IconButton, Grid, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';

interface EditOverlayProps {
  handleClick: () => void;
}

const EditOverlayContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  zIndex: 1,
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out, backgroundColor 0.3s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.light, // semi-transparent on hover
    opacity: 0.4,
  },
}));

const EditOverlay: React.FC<EditOverlayProps> = ({ handleClick }) => {
  return (
    <EditOverlayContainer
      container
      onClick={handleClick}
      justifyContent="center"
      alignItems="center"
    >
      <Stack spacing={2}>
        <IconButton>
          <EditIcon fontSize="large" style={{ color: 'white' }} />
        </IconButton>
      </Stack>
    </EditOverlayContainer>
  );
};

export default EditOverlay;
