import React, { ChangeEvent } from 'react';
import { Grid, InputLabel, TextField } from '@mui/material';

interface Field {
  title: string;
  description: string;
  value: string;
  required: boolean;
  index: number;
  errors?: {
    required?: boolean;
  };
}

interface TimeProps {
  field: Field;
  setField: React.Dispatch<React.SetStateAction<Field>>;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  fieldNumber: number;
}

const Time: React.FC<TimeProps> = (props) => {
  const {
    field,
    setField,
    selected,
    answerable,
    formDisabled,
    fieldNumber
  } = props;
  const { title, description, index, required } = field;

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setField((prevField) => ({
      ...prevField,
      errors: {
        ...prevField.errors,
        required: answerable && required && value === ''
      },
      value
    }));
  };

  if ((!selected) || answerable) {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel htmlFor={`time-${index}`}>
              {fieldNumber + 1}. {title} {required && <b>*</b>}{' '}
            </InputLabel>
            <div style={{ fontSize: 12, marginTop: -8, marginBottom: 8, opacity: 0.8 }}>
              {description}
            </div>
            <TextField
              type="time"
              name={`time-${index}`}
              id={`time-${index}`}
              placeholder={description}
              disabled={formDisabled}
              onChange={setValue}
              value={field.value ? field.value : ''}
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return null;
};

export default Time;
