import {useCallback, useState} from "react";
import Paging from "../../models/Pageable";
import {fetchData} from "../../utils/utils";
import { useSnackbar } from "notistack";

export const useSavedSearches = <T>(
  endpoint: string | null,
  page: number
) => {
  const [results, setResults] = useState(new Paging<T>());

  const { enqueueSnackbar } = useSnackbar();

  const getAll = useCallback(async () => {
    try {
      const response = await fetchData(`${endpoint}?page=${page}`);
      setResults(response)
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" })
    }
  }, [endpoint, enqueueSnackbar, page])

  return {
    results,
    getAll,
  };
};
