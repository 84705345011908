import { DatastreamMimetypeOptions } from "../../pages/Content/Datastreams/DatatsreamTypesList";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useMemo } from "react";
import { DatastreamMimetype } from "../../models/DatastreamMimetype";

type useDatastreamPreviewOutput = {
  FileIcon: OverridableComponent<SvgIconTypeMap>;
  color: string;
};

export const useDatastreamFileImage = (
  mimeType: string,
  typeName?: string
): useDatastreamPreviewOutput => {
  const type = useMemo(() => {
    if (!mimeType && typeName && typeName === "Guide")
      return DatastreamMimetypeOptions.find((x) => x.id === "Guide");

    if (!mimeType && typeName && typeName === "Geo")
      return DatastreamMimetypeOptions.find((x) => x.id === "Geo");

    if (mimeType === "repox/shortcut")
      return DatastreamMimetypeOptions.find((x) => x.id === "Shortcut");

    if (mimeType.includes("image"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Image
      );

    if (mimeType.includes("pdf"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.PDF
      );

    if (mimeType.includes("video"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Video
      );

    if (mimeType.includes("audio"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Audio
      );

    if (mimeType.includes("word"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Word
      );

    if (
      mimeType.includes("excel") ||
      mimeType.includes("officedocument.spreadsheetml")
    )
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Excel
      );

    if (
      mimeType.includes("powerpoint") ||
      mimeType.includes("officedocument.presentation")
    )
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Presentation
      );

    if (mimeType.includes("opendocument"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Open
      );

    if (mimeType.includes("text"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Text
      );

    if (mimeType.includes("octet-stream") || mimeType.includes("model/obj"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.ThreeD
      );

    if (mimeType.includes("zip") || mimeType.includes("rar"))
      return DatastreamMimetypeOptions.find(
        (x) => x.id === DatastreamMimetype.Archive
      );

    return DatastreamMimetypeOptions.find(
      (x) => x.id === DatastreamMimetype.Datastreams
    );
  }, [mimeType, typeName]);

  return useMemo(() => {
    return {
      FileIcon: type?.icon ?? InsertDriveFileOutlinedIcon,
      color: type?.color ?? "#000",
    };
  }, [type]);
};
