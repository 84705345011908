import React from 'react';
import { Box, Typography } from '@mui/material';
import Survey from "../models/Survey";

type IndividualProps = {
  UUIDs: string[] | undefined,
  surveyData: Survey,
  key: number
};

const Individual = (props:IndividualProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 250
      }}
    >
      <Typography variant="h5">Individual View</Typography>
    </Box>
  );
}

export default Individual;
