import { SurveyType } from "./SurveyItem";

export class Question {
  id: string = '';
  type: string = '';
  index?: number;
  title: string = '';
  value: string = '';
  details?: {
    options: string[];
  };
  required: boolean = false;
  description: string = '';
}

export class Section {
  id: string = '';
  title: string = '';
  questions: string[] = [];
  description: string = '';
}

class Survey {
  uuid: string = '';
  label: string = '';
  description: string = '';
  surveyType: SurveyType;
  openAt: string = '';
  closeAt: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  publishedAt: string | null = null;
  publishedBy: number | null = null;
  questions: Question[] = [];
  sections: Section[] = [];
  responseCount: number = 0;
  submissionMessage: string = '';
  locked: boolean = false;
  lockedMessage: string = '';

  constructor(surveyType: SurveyType) {
    this.surveyType = surveyType;
  }
}

export default Survey;
