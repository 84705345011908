import { styled } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const ColoredLink = styled(Typography)(({ theme }) => ({
  // @ts-ignore
  color: theme.palette.primary.main,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

type StyledLinkProps = {
  path: string;
  label: string;
  typography?: boolean;
};

export default function StyledLink({ path, label, typography }: StyledLinkProps) {
  return (
    <ColoredLink>
      <Link to={path} style={{ textDecoration: "none", color: "inherit" }}>
        {typography ? (
          <Typography noWrap variant={"body2"}>
            {" "}
            {label}
          </Typography>
        ) : (
          label
        )}
      </Link>
    </ColoredLink>
  );
}
