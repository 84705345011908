import React, { useState, useEffect } from 'react';
import { Grid, FormGroup, FormControlLabel, Radio, Typography, TextField, Select, MenuItem } from '@mui/material';

interface Details {
  from: number;
  to: number;
  fromLabel: string;
  toLabel: string;
}

interface Field {
  title: string;
  index: number;
  details: Details;
  required: boolean;
  value: number;
  errors: {
    required: boolean;
  };
}

interface LinearScaleProps {
  field: Field;
  setField: (field: Field) => void;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  fieldNumber: number;
}

const LinearScale: React.FC<LinearScaleProps> = ({ field, setField, selected, answerable, formDisabled, fieldNumber }) => {
  const { title, index, details, required } = field;
  const { from, to, fromLabel, toLabel } = details;

  const allOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [fromList, setFromList] = useState(allOptions);
  const [toList, setToList] = useState(allOptions);

  useEffect(() => {
    setToList(allOptions.slice(parseInt(from.toString()) + 1));
    setFromList(allOptions.slice(0, parseInt(to.toString())));
    // eslint-disable-next-line
  }, [from, to]);

  const changeFrom = (event: any) => {
    const value = event.target.value as number;
    setField({
      ...field,
      details: {
        ...details,
        from: value
      }
    });
  };

  const changeTo = (event: any) => {
    const value = event.target.value as number;
    setField({
      ...field,
      details: {
        ...details,
        to: value
      }
    });
  };

  const changeToLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setField({
      ...field,
      details: {
        ...details,
        toLabel: value
      }
    });
  };

  const changeFromLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setField({
      ...field,
      details: {
        ...details,
        fromLabel: value
      }
    });
  };

  const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (formDisabled) return;
    const value = parseInt(event.target.value);
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable && required && isNaN(value)
      },
      value
    });
  };

  // const checkErrors = () => {
  //   if (!answerable) return;
  //   if (required) {
  //     if (isNaN(field.value)) {
  //       setField({
  //         ...field,
  //         errors: {
  //           required: true
  //         }
  //       });
  //     }
  //   }
  // };

  // const requiredError = field.errors && field.errors.required;
  // const hasError = requiredError;

  if ((!selected) || answerable) {
    return (
      <>
        <Typography variant="h6">{fieldNumber + 1}. {title} {required && <b>*</b>}</Typography>

        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography>{fromLabel}</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormGroup row>

              {allOptions.slice(from, to - from + 1).map((option, i) =>
                <FormControlLabel key={i} control={
                  <Radio
                    checked={option === field.value}
                    onChange={setValue}
                    value={option.toString()}
                    name={`linearScale-${index}`}
                    disabled={formDisabled}
                  />
                } label={option} />
              )}

            </FormGroup>
          </Grid>
          <Grid item>
            <Typography align="right">{toLabel}</Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid item container spacing={2}>
        <Grid item xs={6} spacing={2}>
          <Grid item container>
            <Grid item xs={6}>
              <Select
                label={"From"}
                value={from}
                onChange={changeFrom}
                inputProps={{ id: `linear-scale-from-${index}` }}
              >
                {fromList.map((option, i) => <MenuItem value={option} key={i}>{option}</MenuItem>)}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Select
                label={"To"}
                value={to}
                onChange={changeTo}
                disabled={formDisabled}
                inputProps={{ id: `linear-scale-to-${index}` }}
              >
                {toList.map((option, i) => <MenuItem value={option} key={i}>{option}</MenuItem>)}
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="From Label"
                value={fromLabel}
                onChange={changeFromLabel}
                disabled={formDisabled}
                fullWidth
                id={`linear-scale-from-${index}`}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="To Label"
                value={toLabel}
                onChange={changeToLabel}
                disabled={formDisabled}
                fullWidth
                id={`linear-scale-to-label-${index}`}
              />
            </Grid>
          </Grid>
      </Grid>
      </Grid>
    </>
  );
}

export default LinearScale;
