import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Switch,
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormControlLabel, TextField, Tabs, Tab, Divider
} from "@mui/material";
import { useParams } from 'react-router-dom';
import DeleteIcon from "@mui/icons-material/Delete";
import Summary from "../summary/Summary";
import TableView from "./tableView/TableView";
import Individual from "./Individual";
import { fetchData } from "../../../utils/utils";
import { API_URL } from "../../../utils/config";
import mockDataResponses from "../mockData/srv.surveys.uuid.responses_uuids.json";
import { useSnackbar } from "notistack";
import DownloadIcon from '@mui/icons-material/Download';
import { ResponseUuids } from "../models/Summary";
import Survey from "../models/Survey";

type ResponsesProps = {
  surveyData: Survey
};

const Responses = (props: ResponsesProps) => {
  const { surveyData } = props;
  const { uuid } = useParams<{ uuid: string; subpage: string; }>();
  // eslint-disable-next-line
  const [isLoadingDel, setIsLoadingDelete] = useState<boolean>(false);
  // eslint-disable-next-line
  const [deletedAll, setDeletedAll] = useState<boolean>(false);
  const [result, setResult] = useState<ResponseUuids>();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=> {
    fetchData(`${API_URL}/srv/surveys/${uuid}/responses_uuid`)
      .then((data) => {
        setResult(data)
        setLoading(false);
      })
      .catch((ex) => {
        if (mockDataResponses) {
          // @ts-ignore
          setResult(mockDataResponses);
        } else {
          enqueueSnackbar(ex.message, { variant: "error" });
        }
        setLoading(false);
      });

  }, [enqueueSnackbar, uuid, isVisible]);

  const onVisibilityChange = () => setIsVisible(document.hidden);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  });

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  if (!surveyData) return <></>;

  function setOpenDeleteDialog(arg0: boolean): void {
        throw new Error('Function not implemented.');
    }

  function downloadExcel() {

  }

  function setLocked() {

  }

  function setLockMessage() {

  }

  return (
        <Grid item xs={12}>
          <Container maxWidth={'md'}>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={2}>
              <Tabs centered value={tabValue} onChange={handleChangeTab} aria-label="Survey Tabs">
                <Tab label="Summary" {...a11yProps(0)} />
                <Tab label="Individual" {...a11yProps(1)} />
                <Tab label="List" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <Box mb={3} mt={2}>
              <Grid container spacing={2} mb={4}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" component="h3">
                    {result && result.responseUuids && <>{result.responseUuids.length} Responses</>} &nbsp;
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }} className='mt-1'>
                  <Grid container spacing={2}>
                    {result && result.responseUuids &&
                      <Grid item xs={12} className='mb-4'>
                        <Button variant="outlined" color="info" onClick={downloadExcel}>
                          {downloadLoading ? <CircularProgress /> : <DownloadIcon />} &nbsp; Download Excel
                        </Button> {' '}
                        <Button variant="outlined" color="error" onClick={() => setOpenDeleteDialog(true)}>
                          <DeleteIcon /> &nbsp; Delete all
                        </Button> {' '}
                      </Grid>
                    }
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Switch checked={!surveyData.locked} onChange={setLocked} />}
                        label="Accepting responses"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {surveyData.locked &&
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="locked-message"
                      label="Message for respondents*"
                      type='text'
                      placeholder='e.g. Submissions are closed for now'
                      onChange={setLockMessage}
                      value={surveyData.lockedMessage}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              }
              <Divider />
            </Box>

            {(loading || isLoadingDel) && <CircularProgress />}
            {tabValue === 0 && <Summary key={surveyData.responseCount} />}
            {tabValue === 1 && <Individual UUIDs={result?.responseUuids} surveyData={surveyData} key={surveyData.responseCount} />}
            {tabValue === 2 && <TableView surveyData={surveyData} key={surveyData.responseCount} />}
          </Container>
        </Grid>
  );
}

export default Responses;
