import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import Divider from "@mui/material/Divider";
import {ReactNode} from "react";

type BasicPopperProps = {
  open: boolean
  anchorEl?: Element | ((element: Element) => Element) | null
  handleClose: () => void
  title: string
  children: ReactNode
  id?: string
}

function BasicPopper(props: BasicPopperProps) {
  const { open, anchorEl, handleClose, title, children, id } = props
  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          marginTop: 8,
        },
      }}
    >
      <Stack pl={2} py={1} pr={1} alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
        <Typography variant={"body1"}>{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </Stack>
      <Divider/>
      {children}
    </Popover>
  )
}

export default BasicPopper