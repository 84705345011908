import DisplayOptions from "./DisplayOptions";
import DataOptions from "./DataOptions";
import ValidationOptions from "./ValidationOptions";
import {PropertyType} from "./PropertyType";

export interface SystemSetting {
  settingKey:        string;
  label:             string;
  description:       string;
  settingType:       PropertyType;
  value:             string[] | boolean | number | string;
  mandatory:         boolean;
  editable:          boolean;
  settingGroup:      string;
  displayOptions:    DisplayOptions;
  dataOptions:       DataOptions;
  validationOptions: ValidationOptions;
  position:          number;
}

export enum SystemSettingKey {
  ui_dialog_prevent_dismiss = "ui.dialog.prevent_dismiss",
  ui_content_properties_showOnlyValues = "ui.content.properties.showOnlyValues",
  branding_welcomeImage = "branding.welcomeImage",
  branding_copyrightLink = "branding.copyrightLink",
  branding_loginText = "branding.loginText",
  branding_copyrightText = "branding.copyrightText",
  branding_welcomeText = "branding.welcomeText",
  branding_welcomeTagline = "branding.welcomeTagline",
  branding_logoImage = "branding.logoImage",
  branding_bannerImage = "branding.bannerImage",
  debug_mode_enabled = "debug.mode.enabled",
  system_timezone = "system.timezone"
}