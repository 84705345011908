import React from "react";
import SurveyItem from "../models/SurveyItem";
import DefaultTable from "../../../components/DefaultTable";
import { TableRow } from "@mui/material";
import { ActualDateTime } from "../../../utils/utils";
import ContextActionType from "../../../models/ContextActionType";
import useSortedList from "../../../hooks/sort/useSortedList";
import { useDefaultTable } from "../../../hooks/defaultTable/useDefaultTable";
import { useSortHandler } from "../../../hooks/sort/useSortHandler";

const headCells = [
  {
    id: "label",
    label: "table.surveyName",
    isSortable: true,
  },
  {
    id: "description",
    label: "table.description",
    isSortable: true,
  },
  {
    id: "updatedAt",
    label: "table.lastUpdate",
    isSortable: true,
  },
];

type SurveyItemsListViewProps = {
  items: SurveyItem[];
  selectedItemUUID: string;
  handleOnClick?: React.MouseEventHandler<HTMLElement>;
  handleOnDoubleClick?: React.MouseEventHandler<HTMLElement>;
  handleItemAction?: (action: ContextActionType, id?: string) => void;
};

function SurveyItemsListView({
                               items,
                               selectedItemUUID,
                               handleOnClick,
                               handleOnDoubleClick,
                             }: SurveyItemsListViewProps) {
  const sortHandler = useSortHandler();
  const sorted = useSortedList(sortHandler, items);
  const { renderBodyCell, renderHeadCell } = useDefaultTable(
    sorted,
    sortHandler,
    "uuid"
  );

  const renderDefaultBody = () => {
    return sorted.map((item: SurveyItem, index: number) => {

      const isActive =
        // selectedObjectType === ObjectType.CONTAINER &&
        selectedItemUUID === item.uuid;

      return (
        <TableRow
          key={index}
          id={item.uuid}
          onClick={handleOnClick}
          onDoubleClick={handleOnDoubleClick}
          sx={{
            backgroundColor: isActive
              ? "background.active"
              : "background.paper",
            "&:hover": {
              backgroundColor: isActive ? "background.active" : "none",
            },
          }}
        >
          {renderBodyCell(item.label)}
          {renderBodyCell(item.description)}
          {renderBodyCell(ActualDateTime(item.updatedAt))}
        </TableRow>
      );
    });
  };

  return (
    <DefaultTable
      headCells={headCells}
      renderDefaultBody={renderDefaultBody}
      renderHeadCell={renderHeadCell}
      rows={items}
    />
  );
}

export default SurveyItemsListView;
