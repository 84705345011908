import { Box, CircularProgress, Container, Paper } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PaginationControl from '../../../pages/Content/Containers/Common/PaginationControl';
import { ActualDate, ActualTime, fetchData } from "../../../utils/utils";
import { API_URL } from '../../../utils/config';
import mockData from "../mockData/srv.surveys.uuid.activity.json";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import { TimelineOppositeContent } from "@mui/lab";
import Typography from "@mui/material/Typography";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Activity = () => {
  let query = useQuery();
  const pageVar = query.get('page');
  const { uuid } = useParams<{ uuid: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [activity, setActivity] = useState<any>();
  const [page, setPage] = useState(pageVar ? +pageVar : 0);

  const getActivity = () => {
    setIsLoading(true);
    fetchData(`${API_URL}/addons/srv/surveys/${uuid}/activity?page=${page}`)
      .then(response => {
        const { data } = response;
        setActivity(data);
        setIsLoading(false);
      })
      .catch(() => {
        if (mockData) {
          setActivity(mockData);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getActivity();
    // eslint-disable-next-line
  }, [page]);

  return (
    <Container maxWidth={'md'}>
      <Box>
        <Box sx={{ my: 2 }}>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 350,
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
          {activity && !isLoading ? (
            <Box>
              <Timeline sx={{ py: 0 }}>
                {activity.content.map((event: { createdAt: string; description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
                  <TimelineItem style={{ margin: 0, padding: 0, marginLeft: '-550px' }} key={index}>
                    <TimelineOppositeContent sx={{ py: 0, px: 1, width: 150 }}>
                      <Typography variant="body2" color="textSecondary">
                        {ActualDate(event.createdAt)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {ActualTime(event.createdAt)}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <ArrowCircleRightOutlinedIcon
                        fontSize={"medium"}
                        color={"primary"}
                      />
                      <TimelineConnector sx={{ backgroundColor: "secondary.main" }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: 0, pb: 4, pl: 2 }}>
                      <Paper elevation={4} sx={{ height: "auto" }}>
                        <Typography variant="body2" sx={{ p: 1 }}>
                          {event.description}
                        </Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>

              {/*{activity.content.map((activity: any, key: number) => (*/}
              {/*  <ListItem key={key}>*/}
              {/*    <ListItemText*/}
              {/*      primary={moment(activity.createdAt).format('DD/MM/YYYY')}*/}
              {/*      secondary={activity.description}*/}
              {/*    />*/}
              {/*  </ListItem>*/}
              {/*))}*/}
            </Box>
          ) : null}
        </Box>

        {activity ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <PaginationControl
              count={activity.totalPages}
              page={page}
              onPageChange={setPage}
              disabled={isLoading}
            />
          </Box>
        ) : null}
      </Box>
    </Container>
  );
};

export default Activity;
