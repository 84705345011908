import React from "react";
import { Button, Stack } from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import ComponentType from "../../models/ComponentType";

type ActionToolbarProps = {
  componentType?: string;
  backUrl?: string;
  onClickActionButton: (cb?: any) => void;
  onReject?: () => void;
  hasDiffs?: boolean;
  children?: any;
  inProgress?: boolean;
  disabledAction?: boolean;
};

function ActionToolbar({
  componentType,
  backUrl,
  onClickActionButton,
  children,
  hasDiffs,
  onReject,
  inProgress,
  disabledAction
}: ActionToolbarProps) {

  const { t } = useTranslation()

  const backTextList= new Map([
    [ComponentType.LIST, t("guides.list.back")],
    [ComponentType.ITEM, t("guides.items.back")],
    [ComponentType.PAGE, t("guides.pages.back")],
    [ComponentType.ROUTE, t("guides.routes.back")],
    ['none', t("buttons.back")]
  ])

  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      alignItems={"center"}
      columnGap={1}
    >
      {backUrl && (
        <Link to={backUrl} style={{ textDecoration: "none", color: "inherit" }}>
          <Button startIcon={<ArrowBackIosOutlinedIcon />} sx={{textTransform:'none'}}>
            {backTextList.get(componentType??'none')}
          </Button>
        </Link>
      )}
      <div style={{ flexGrow: 1 }} />
      {children && children}
      <Button disabled={!hasDiffs} onClick={onReject}>Discard</Button>
      <LoadingButton
        loading={inProgress}
        disabled={(onReject && !hasDiffs) || (disabledAction)}
        onClick={onClickActionButton}
        variant={"contained"}
      >
        {t("buttons.save").toString()}
      </LoadingButton>
    </Stack>
  );
}

export default ActionToolbar;
