import {SortHandler} from "./useSortHandler";
import {useMemo} from "react";

function useSortedList<T>(sortHandler: SortHandler, list: T[]) {

  return useMemo(() => {
    if (!sortHandler.sort.by) {
      return list
    }
    return list
      .sort((a: T, b : T) => {
        if (sortHandler.sort.direction === "asc") {
          if (sortHandler.sort.by === "label") {
            // @ts-ignore
            return a[sortHandler.sort.by].toLowerCase() < b[sortHandler.sort.by].toLowerCase()  ? -1 : 1
          }
          // @ts-ignore
          return a[sortHandler.sort.by] < b[sortHandler.sort.by]  ? -1 : 1
        } else {
          if (sortHandler.sort.by === "label") {
            // @ts-ignore
            return a[sortHandler.sort.by].toLowerCase()  < b[sortHandler.sort.by].toLowerCase()  ? 1 : -1
          }
          // @ts-ignore
          return a[sortHandler.sort.by]  < b[sortHandler.sort.by] ? 1 : -1
        }

      })
  }, [list, sortHandler.sort.by, sortHandler.sort.direction])
}

export default useSortedList
