import React, {Fragment} from "react";
import {ReactComponent as ErrorImage} from "../assets/images/undraw_fixing_bugs_w7gi.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {logError, LogLevel} from "../utils/utils";

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: null, errorInfo: null};
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
		// You can also log error messages to an error reporting service here
		const info = {
			path: window.location.href,
			componentStack: errorInfo.componentStack,
			component: this.props.component,
			data: this.props.data
		}
		if (process.env.NODE_ENV === 'development') {
			console.log(LogLevel.ERROR, error.message, JSON.stringify(info))
		} else {
			logError(LogLevel.ERROR, error.message, JSON.stringify(info))
		}
	}

	render() {
		if (this.state.errorInfo) {
			// Error path
			return (
				<Stack p={4} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} rowGap={2}>
					<ErrorImage height={300}/>
					<Typography variant={"overline"}>Oops! Something went wrong.</Typography>
				</Stack>
			);
		}
		// Normally, just render children
		return <Fragment>{this.props.children}</Fragment>;
	}
}
