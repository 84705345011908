import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import {
  Box,
  Button,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const MultipleChoiceGrid = (props: any) => {
  // Props destructuring
  const { field, setField, selected, answerable, formDisabled, fieldNumber } = props;
  const { title, description, index, details, required } = field;
  const { columns, rows } = details;

  // State Hooks
  const [newOptionRow, setNewOptionRow] = useState<string>('');
  const [newOptionColumn, setNewOptionColumn] = useState<string>('');
  const [addedNew, setAddedNew] = useState<boolean>(false);

  const onDragEnd = (result: DropResult, type: string | number) => {

    // dropped outside the list
    if (!result.destination) {
      return
    }

    setField({
      ...field,
      details: {
        ...details,
        [type]: reorder(
          details[type],
          result.source.index,
          result.destination.index
        )
      }
    })
  }

  // Helper to set new options
  const setOptionsRow = (newOptions: any[]) => {
    setField({
      ...field,
      details: {
        ...details,
        rows: [...newOptions]
      }
    });
  };

  // Helper to set new options
  const setOptionsColumn = (newOptions: any[]) => {
    setField({
      ...field,
      details: {
        ...details,
        columns: [...newOptions]
      }
    });
  };

  // Handle change
  const changeOptionRow = (e: any, index: number) => {
    const value = e.target.value;
    let newOptions = [...rows];
    newOptions[index] = {
      id: newOptions[index].id,
      value
    };
    setOptionsRow(newOptions);
  };

  // Handle change
  const changeOptionColumn = (e: any, index: number) => {
    const value = e.target.value;
    let newOptions = [...columns];
    newOptions[index] = {
      id: newOptions[index].id,
      value
    };
    setOptionsColumn(newOptions);
  };

  // Handle deletion
  const deleteOptionRow = (index: number) => {
    let newOptions = [...rows];
    newOptions.splice(index, 1);
    setOptionsRow(newOptions);
  };

  // Handle deletion
  const deleteOptionColumn = (index: number) => {
    let newOptions = [...columns];
    newOptions.splice(index, 1);
    setOptionsColumn(newOptions);
  };

  // Handle addition
  const changeNewOption = (e: any, type: string) => {
    const value = e.target.value;
    if (type === "column") {
      setNewOptionColumn(value);
    } else {
      setNewOptionRow(value);
    }
  };

  // Create new entry on change
  useEffect(() => {
    if (newOptionRow && newOptionRow !== "") {
      let newOptionRows = [...rows];
      newOptionRows.push({
        id: `option-row-${new Date().valueOf()}`,
        value: newOptionRow
      });
      setOptionsRow(newOptionRows);
      setNewOptionRow('');
      setAddedNew(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOptionRow]);

  // Create new entry on change
  useEffect(() => {
    if (newOptionColumn && newOptionColumn !== "") {
      let newOptionColumns = [...columns];
      newOptionColumns.push({
        id: `option-column-${new Date().valueOf()}`,
        value: newOptionColumn
      });
      setOptionsColumn(newOptionColumns);
      setNewOptionColumn('');
      setAddedNew(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOptionColumn]);

  // Blur when previewing/editing
  useEffect(() => {
    setAddedNew(false);
  }, [selected, answerable]);

  const setValue = (row: any, column: any) => {
    const newValues = [...field.value];
    const rowObjIndex = newValues.findIndex((r: any) => r.id === row.id);

    if (rowObjIndex !== -1) {
      if (newValues[rowObjIndex].value === column.id) {
        newValues.splice(rowObjIndex, 1);
      } else {
        newValues[rowObjIndex].value = [column.id];
      }
    } else {
      newValues.push({
        id: row.id,
        value: [column.id]
      });
    }

    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable && required && newValues.length === 0
      },
      value: newValues
    });
  };

  const checkErrors = () => {
    if (!answerable) return;
    if (required) {
      if (field.value.length === 0) {
        setField({
          ...field,
          errors: {
            required: true
          }
        });
      }
    }
  };

  // eslint-disable-next-line
  const requiredError = field.errors && field.errors.required;

  // The view / preview part
  //////////////////////////
  if ((!selected) || answerable) return (
    <>
      <Typography variant="h6">{fieldNumber + 1}. {title} {required && <b>*</b>}</Typography>
      <Typography variant="body2" style={{marginTop: -8, marginBottom: 8, opacity: 0.8}}>{description}</Typography>

      <Grid container>
        <Grid item lg={12}>
          <Box>
            <Table className="table-centered table-borderless multiple-choice-grid">
              <TableHead>
                <TableRow>
                  <TableCell width={'60%'}> &nbsp; </TableCell>
                  {details.columns.map((column: any,i:number)=><TableCell key={i} align="center">{column.value}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {details.rows.map((row:any,x:number)=>{
                  return (
                    <TableRow key={x}>
                      <TableCell>{row.value}</TableCell>
                      {details.columns.map((column: any, y:number)=>{

                        const checked = field.value&&field.value.find((r:any)=>r.id===row.id&&r.value[0]===column.id)

                        return (
                          <TableCell key={y} align="center">
                            <Radio
                              checked={checked}
                              onChange={()=>setValue(row,column)}
                              onBlur={checkErrors}
                              disabled={formDisabled}
                              color="primary"
                            />
                          </TableCell>)
                      })}
                    </TableRow>)
                })}
              </TableBody>
            </Table>
          </Box>
        </Grid>
      </Grid>
    </>);

  // The editor part
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6">Rows</Typography>
        <DragDropContext onDragEnd={(r) => onDragEnd(r, "rows")}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Grid item container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                {details.rows.map((option: any, optionIndex: number) => {
                  const shouldFocus = addedNew && optionIndex === details.rows.length - 1;

                  return (
                    <Draggable key={`radio-${index}-option-${optionIndex}`} draggableId={`radio-${index}-option-${optionIndex}`} index={optionIndex}>
                      {(provided) => (
                        <Grid item container spacing={2} alignItems="center" key={optionIndex} {...provided.draggableProps} ref={provided.innerRef}>
                            <Grid item xs>
                              <TextField
                                fullWidth
                                type="text"
                                name={`radio-${index}-option-${optionIndex}-label`}
                                id={`radio-${index}-option-${optionIndex}-label`}
                                placeholder="Label"
                                value={option.value}
                                onChange={(e) => changeOptionRow(e, optionIndex)}
                                autoFocus={shouldFocus}
                                disabled={formDisabled}
                              />
                            </Grid>
                            <Grid item>
                              <Button variant="contained" color="error" onClick={() => deleteOptionRow(optionIndex)}>Delete</Button>
                            </Grid>
                          </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
          <Box width={'100%'} pt={2} pb={1}>
            <TextField
              fullWidth
              type="text"
              name="new-option"
              id="new-option"
              placeholder="Add new option"
              value={newOptionRow}
              onChange={(e) => changeNewOption(e, "row")}
              disabled={formDisabled}
            />
          </Box>
        </DragDropContext>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h6">Columns</Typography>
        <DragDropContext onDragEnd={(r) => onDragEnd(r, "columns")}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Grid item container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                {details.columns.map((option: any, optionIndex: number) => {
                  const shouldFocus = addedNew && optionIndex === details.columns.length - 1;

                  return (
                    <Draggable key={`radio-${index}-option-${optionIndex}`} draggableId={`radio-${index}-option-${optionIndex}`} index={optionIndex}>
                      {(provided) => (
                        <Grid item container spacing={2} alignItems="center" key={optionIndex} {...provided.draggableProps} ref={provided.innerRef}>
                            <Grid item xs>
                              <TextField
                                fullWidth
                                type="text"
                                name={`radio-${index}-option-${optionIndex}-label`}
                                id={`radio-${index}-option-${optionIndex}-label`}
                                placeholder="Label"
                                value={option.value}
                                onChange={(e) => changeOptionColumn(e, optionIndex)}
                                autoFocus={shouldFocus}
                                disabled={formDisabled}
                              />
                            </Grid>
                            <Grid item>
                              <Button variant="contained" color="error" onClick={() => deleteOptionColumn(optionIndex)}>Delete</Button>
                            </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
          <Box width={'100%'} pt={2} pb={1}>
            <TextField
              fullWidth
              type="text"
              name="new-option"
              id="new-option"
              placeholder="Add new option"
              value={newOptionColumn}
              onChange={(e) => changeNewOption(e, "column")}
              disabled={formDisabled}
            />
          </Box>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

export default MultipleChoiceGrid;
