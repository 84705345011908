import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography, Card, Stack } from "@mui/material";
import { useParams } from 'react-router-dom';
import AnswerRenderer from './AnswerRenderer';
import { ANSWER_COMPONENTS } from './consts';
import NoResponses from '../responses/NoResponses';
import { fetchData } from "../../../utils/utils";
import { API_URL } from "../../../utils/config";
import mockData from "../mockData/srv.surveys.uuid.summary.json";

interface Params {
  uuid: string;
}

interface Section {
  questions: number[];
  title: string;
  description: string;
}

interface Response {
  questionId: number;
  questionType: string;
}

interface SummaryInterface {
  totalResponseCount: number;
  sections: Section[];
  totalResponseSummary: Response[];
}

const Summary = () => {
  // @ts-ignore
  const { uuid } = useParams<Params>();
  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState<SummaryInterface | null>(null);
  const [isVisible, setIsVisible] = useState<boolean | null>(null);

  const getSummary = () => {
    setIsLoading(true);
    fetchData(`${API_URL}/addons/srv/surveys/${uuid}/summary`)
      .then(response => {
        setSummary(response);
        setIsLoading(false);
      })
      .catch(() => {
        if (mockData) {
          // @ts-ignore
          setSummary(mockData);
        }
        setIsLoading(false);
      });
  };

  const onVisibilityChange = () => setIsVisible(document.hidden);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) {
      getSummary();
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line
  }, []);

  const createMarkup = (htmlcode: string) => {
    return { __html: htmlcode };
  };

  return (
    <>
        {isLoading && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: 350 }}
          >
            <CircularProgress />
          </Grid>
        )}

        {!isLoading && summary && summary.totalResponseCount !== 0 && (
          <Stack direction={'column'} gap={2}>
            {summary.sections.map((section, sectionIndex) => {
              const sectionFields = summary.totalResponseSummary.filter(f =>
                section.questions.includes(f.questionId)
              );

              return (
                <Stack key={`section-${sectionIndex}`} gap={2}>
                  {section.title && (
                    <Box mt={2}>
                      <Typography variant="h5">{section.title}</Typography>
                      {section.description !== '' && (
                        <Typography variant="subtitle1">
                          <div dangerouslySetInnerHTML={createMarkup(section.description)} />
                        </Typography>
                      )}
                    </Box>
                  )}

                  {!section.title && <Box height="16px" />}

                  {sectionFields.map((response, sectionFieldIndex) => {
                    // @ts-ignore
                    if (!ANSWER_COMPONENTS[response.questionType]) return null;

                    return ( <Stack>
                        <Card elevation={1} key={`field-${sectionFieldIndex}`} sx={{padding:2}}>
                          <AnswerRenderer field={response} fieldNumber={sectionFieldIndex} />
                        </Card>
                    </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        )}

        {!isLoading && summary && summary.totalResponseCount === 0 && <NoResponses />}
    </>
  );
};

export default Summary;
