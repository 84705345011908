import {ANSWER_COMPONENTS} from "./consts";
import * as React from "react";

// This will render the appropriate type of field
// Check components/fields
const AnswerRenderer = (props:any) => {
  return <>
    {
      // @ts-ignore
      React.createElement(ANSWER_COMPONENTS[props.field.questionType], {...props}) }
  </>
}

export default AnswerRenderer;
