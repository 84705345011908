import {
  buildParameters,
  deleteData,
  fetchData,
  postData,
  updateData,
} from "../utils/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import Paging from "../models/Pageable";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery, { updateQueryStringParameter } from "../hooks/query/useQuery";

export interface Entity<T> {
  list: [T];
  initialized: boolean;
  isLoading: boolean;
  getAll: (callback?: () => void) => void;
  create: (payload: any, callback?: () => void) => void;
  update: (payload: any, callback?: () => void) => void;
  remove: (payload: string, callback?: () => void) => void;
}

export const createGetAllMethod = async(
  url: string,
  set: (state: any) => void,
  callback?: (error: any, data: any) => void
) => {
  set({ isLoading: true })
  try {
    const response = await fetchData(url);
    set({ list: response, initialized: true });
    callback && callback(null, response);
  } catch (ex) {
    set({ initialized: true });
    callback && callback(ex, null);
  }
  set({ isLoading: false })
};

export const createUpdateMethod = async <T>(
  url: string,
  payload: T,
  set: (state: any) => void,
  identifier : string,
) => {
  // @ts-ignore
  const response = await updateData(url, payload);
  set((prevState: Entity<T>) => ({
    list: prevState.list.map((item) => {
      // @ts-ignore
      if (item[identifier] === response[identifier]) {
        return response;
      }
      return item;
    }),
  }));
};

export const createPostMethod = async <T>(
  url: string,
  payload: T,
  set: (state: any) => void,
) => {
  // @ts-ignore
  const response = await postData(url, payload);
  set((prevState: Entity<T>) => ({
    list: [...prevState.list, response]
  }))
};

export const createDeleteMethod = async <T>(
  url: string,
  entityId: T,
  set: (state: any) => void,
  identifier:string,
) => {
    // @ts-ignore
  await deleteData(url);
  set((prevState: Entity<T>) => ({
    // @ts-ignore
    list: prevState.list.filter(u => u[identifier] !== entityId)
  }))
};

type EndpointMap = {
  getAll: string,
  update: string,
  create: string,
  remove: string
}

export const createCommonStore = <T>(endpoint: string | EndpointMap, identifier: string) => (set: (value: any) => void): any => ({
  list: [],
  initialized: false,
  isLoading: false,
  getAll: async (callback?: () => void) => {
    // const response = await fetch(pond)
    const url = typeof endpoint === "string" ? endpoint : endpoint.getAll
    await createGetAllMethod(url, set, callback);
  },
  update: async (entity: T) => {
    const url = typeof endpoint === "string" ? endpoint : endpoint.update
    // @ts-ignore
    await createUpdateMethod<T>(`${url}/${entity[identifier]}`, entity, set, identifier);
  },
  create: async (entity: T) => {
    const url = typeof endpoint === "string" ? endpoint : endpoint.create
    await createPostMethod<T>(url, entity, set);
  },
  remove: async (entityId: string) => {
    const url = typeof endpoint === "string" ? endpoint : endpoint.remove
    await createDeleteMethod(`${url}/${entityId}`, entityId, set, identifier);
  },
})

export const useEntityList = <T>(store: Entity<T>): [T[], boolean] => {
  const { list, initialized, isLoading, getAll } = store

  useEffect(() => {
    let time = setTimeout(() => {
      if (!initialized && !isLoading) {
        getAll();
      }
    }, 100)

    return () => {
      clearTimeout(time)
    }

  }, [getAll, initialized, isLoading]);

  return [list, isLoading];
};

const defaultAllowedParams = ["page", "q", "sort"]

export const usePaginatedEntity = <T>(endpoint: string, identifier: string, allowedParams: string[] = defaultAllowedParams, mockData?: any) => {
  const [results, setResults] = useState(new Paging<T>())
  // const [searchParams, setSearchParams] = useSearchParams();
  const searchParams = useQuery()
  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  const page = parseInt(searchParams.get("page") ?? "0")

  const handleUpdateParameter = useCallback((propertyKey: string, propertyValue: string) => {
    const newSearch = updateQueryStringParameter(search, propertyKey, propertyValue)
    navigate(`${pathname}/${newSearch}`)
  }, [navigate, pathname, search])

  const setPage = useCallback((newPage: number) => {
    handleUpdateParameter("page", newPage.toString())
  }, [handleUpdateParameter])

  const queryParameters = useMemo(() => {
    const obj: any = {}
    searchParams.forEach((value, key) => {
      if (allowedParams.includes(key)) {
        obj[key] = value
      }
    });
    return buildParameters(obj, endpoint.includes("?"))
  }, [allowedParams, endpoint, searchParams])

  const { enqueueSnackbar } = useSnackbar()

  const getAll = useCallback(async () => {
    try {
      const response = await fetchData(`${endpoint}${queryParameters}`);
      setResults(response)
    } catch (ex: any) {
      if (mockData) {
        setResults(mockData)
      } else {
        enqueueSnackbar(ex.message, { variant: "error" })
      }
    }
  }, [endpoint, enqueueSnackbar, queryParameters, mockData])

  const create = async (payload: any) => {
    try {
      await postData(endpoint, payload);
      await getAll()
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" })
    }
  }

  const update = async (payload: any) => {
    try {
      await updateData(`${endpoint}/${payload[identifier]}`, payload);
      await getAll()
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" })
    }
  }

  const remove = async (entityId: string) => {
    try {
      await deleteData(`${endpoint}/${entityId}`);
      await getAll()
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" })
    }
  }

  return {
    getAll,
    create,
    update,
    remove,
    results,
    page,
    setPage
  }

}
