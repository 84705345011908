import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";
import GridOnIcon from "@mui/icons-material/GridOn";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { useTranslation } from "react-i18next";
import { ListViewHandler } from "../../../../hooks/common/useListView";
import { SortHandler } from "../../../../hooks/sort/useSortHandler";
import { ObjectType } from "../../../../models/ObjectType";
import { StickyHeader } from "../../../../components/Common/StickyHeader";
import SortPopOver, {
  SortOption,
} from "../../../../components/Common/SortPopOver";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  // @ts-ignore
  backgroundColor: alpha(theme.palette.background.neutral, 1),
  "&:hover": {
    // @ts-ignore
    backgroundColor: alpha(theme.palette.background.neutral, 1),
  },
  marginLeft: 0,
  fontSize: 12,
  width: "450px",
  height: "40px",
  display: "flex",
  alignItems: "center",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "500px",
  fontSize: 14,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

type SettingsHeaderProps = {
  title?: string;
  handleRefreshContainer?: () => void;
  listViewHandler?: ListViewHandler;
  sortHandler?: SortHandler;
  sortOptions?: SortOption[];
  children?: ReactNode;
  entityUuid?: string;
  selectedObjectType?: ObjectType;
  query?: string;
  setQuery?: (newValue: string) => void;
  disablePaddingTop?: boolean;
  disableBgrColor?: boolean;
};

function SurveysContentHeader({
  title,
  listViewHandler,
  sortHandler,
  sortOptions,
  children,
  query,
  setQuery,
  disablePaddingTop,
  disableBgrColor,
}: SettingsHeaderProps) {
  const { t } = useTranslation();

  return (
    <StickyHeader
      style={{ backgroundColor: disableBgrColor ? "inherit" : undefined }}
    >
      <Stack pt={disablePaddingTop ? 0 : 2} rowGap={2} pb={1}>
        <Stack
          flexDirection={"row"}
          // alignItems={"center"}
          style={{ minHeight: 20 }}
          columnGap={1}
        >
          {title && <Typography variant={"h6"}>{title}</Typography>}
          <div style={{ flexGrow: 1 }} />
          {setQuery && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon fontSize={"small"} />
              </SearchIconWrapper>
              <StyledInputBase
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={`${t("toolbar.searchbar")}...`}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          )}
          {sortHandler && sortOptions && (
            <SortPopOver sortHandler={sortHandler} sortOptions={sortOptions} />
          )}
          {listViewHandler && listViewHandler.listView && (
            <Tooltip title={t("myContent.gridView").toString()}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  localStorage.setItem("listView", JSON.stringify(false));
                  listViewHandler.handleListView(false);
                }}
              >
                <GridOnIcon color={"action"} />
              </IconButton>
            </Tooltip>
          )}
          {listViewHandler && !listViewHandler.listView && (
            <Tooltip title={t("myContent.listView").toString()}>
              <IconButton
                onClick={() => {
                  localStorage.setItem("listView", JSON.stringify(true));
                  listViewHandler.handleListView(true);
                }}
              >
                <FormatListBulletedOutlinedIcon
                  color={"action"}
                />
              </IconButton>
            </Tooltip>
          )}
          {children && children}
        </Stack>
      </Stack>
    </StickyHeader>
  );
}

export default SurveysContentHeader;
