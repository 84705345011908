import React, { useEffect, useState } from "react";
import { useDatastreamFileImage } from "../../hooks/datastream/useDatastreamFileImage";
import {readFile} from "../../utils/cropImage";
import Avatar from "@mui/material/Avatar";

type FileUploadImageProps = {
  file: File;
};

function FileUploadImage({ file }: FileUploadImageProps) {
  const { FileIcon } = useDatastreamFileImage(file.type);

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    (async () => {
      let imageDataUrl = await readFile(file);
      setImageUrl(imageDataUrl)
    })();
  }, [file]);

  if (imageUrl) {
    return  (
      <Avatar alt="Remy Sharp" src={imageUrl} />
    )
  }

  return <FileIcon fontSize={"large"} />;
}

export default FileUploadImage;
