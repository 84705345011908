import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { useFormik } from "formik";
import * as yup from "yup";
import { Dispatch, SetStateAction } from "react";
import {getTenantId, postData} from "../../utils/utils";
import {API_URL} from "../../utils/config";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(3, "Password should be of minimum 3 characters length")
    .max(50, "Password should be of maximum 50 characters length")
    .required("Password is required"),
});

type PasswordProps = {
  setValid: Dispatch<SetStateAction<boolean>>;
};
const Password = ({ setValid }: PasswordProps) => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const t=getTenantId();
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        await postData(`${API_URL}/share/${params.id}/pwd?t=${t}`,values)
        setValid(true)
      } catch (ex) {
        setValid(false)
        enqueueSnackbar("Wrong password. Try again!", {
          variant: "error",
        });
      }
    },
  });

  return (
    <Container maxWidth="xs">
      <Card variant={"outlined"} sx={{ border: "none", mt: 4 }}>
        <CardContent>
          <Stack direction={"column"} alignItems={"center"} rowGap={1}>
            <Typography variant={"subtitle1"} color={"primary"}>
              The link is password protected
            </Typography>
            <Typography variant={"subtitle2"} color={"primary"}>
              Please enter the password to view the content
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                inputProps={{
                  autoComplete: "new-password",
                }}
                helperText={
                  formik.touched.password &&
                  formik.errors.password && (
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      sx={{ color: "red", mt: 0, pt: 0 }}
                    >
                      {formik.errors.password}
                    </Typography>
                  )
                }
              />
              <Button
                sx={{ mt: 2, alignSelf: "center" }}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </form>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Password;
