import React from 'react';
import MultipleChoices from "../common/MultipleChoices";

const MultipleChoice = (props:any) => {
  const {field, fieldNumber} = props

  return <MultipleChoices field={field} fieldNumber={fieldNumber} />
}

export default MultipleChoice;
