import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Copyright } from "../Login/Login";
import ForgotPasswordImage from "../../assets/images/undraw_forgot_password_re_hxwm.svg";
import { postData } from "../../utils/utils";
import { API_URL } from "../../utils/config";
import { useSnackbar } from "notistack";
import {useTranslation} from "react-i18next";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const payload = {
        email: values.email,
      };
      try {
        await postData(API_URL + `/auth/forgot_password`, payload);
        enqueueSnackbar("Check your email !", {
          variant: "info",
        });
      } catch (ex) {
        enqueueSnackbar("Something went wrong. Try again!", {
          variant: "error",
        });
      }
    },
  });

  return (
    <Grid container>
      <img
        src={require("../../assets/images/store-transparent.png")}
        style={{
          position: "absolute",
          width: "50px",
          marginTop: 40,
          marginLeft: 40,
        }}
        alt={"Logo"}
      />
      <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
        <Stack
          flexDirection={"column"}
          gap={1}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <img
            src={ForgotPasswordImage}
            alt="login"
            style={{ width: 600, paddingTop: 20 }}
          />
        </Stack>
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <Container
          maxWidth={"lg"}
          sx={{ backgroundColor: "background.paper", boxShadow: 8 }}
        >
          <Stack
            flexDirection={"column"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100vh"}
          >
            <Typography variant={"h6"} color={"primary.main"}>
              {t("forgot.title").toString()}
            </Typography>
            <form onSubmit={formik.handleSubmit} style={{ width: "80%" }}>
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                id="email"
                name="email"
                label={t("formElement.email").toString()}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.touched.email &&
                  formik.errors.email && (
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      sx={{ color: "red", mt: 0, pt: 0 }}
                    >
                      {formik.errors.email}
                    </Typography>
                  )
                }
              />
              <Button
                sx={{ mt: 2 }}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                {t("forgot.reset").toString()}
              </Button>
            </form>
            <Link
              to={"/login"}
              style={{ marginTop: 30, textDecoration: "none" }}
            >
              <Typography variant={"caption"} color={"primary"}>
                {t("forgot.forgorMsg").toString()}
              </Typography>
            </Link>
            <Copyright />
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
