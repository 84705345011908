import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormGroup, RadioGroup, Radio, FormControlLabel, FormControl, Checkbox, Grid, TextField, Button, Typography } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface FieldProps {
  title: string,
  description: string,
  index: number,
  details: {
    options: string[],
    hasOtherOption: boolean,
  },
  required: boolean,
  errors?: any,
  value?: string,
}

interface MultipleChoiceProps {
  field: FieldProps,
  setField: React.Dispatch<React.SetStateAction<FieldProps>>,
  selected: boolean,
  answerable: boolean,
  formDisabled: boolean,
  fieldNumber: number,
}

const MultipleChoice: React.FC<MultipleChoiceProps> = ({ field, setField, selected, answerable, formDisabled, fieldNumber }) => {
  const { title, description, index, details, required } = field;
  const [newOption, setNewOption] = useState('');
  // eslint-disable-next-line
  const [addedNew, setAddedNew] = useState(false);
  const { options, hasOtherOption } = details;

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedOptions = [...options];
    const [removed] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, removed);

    setField({
      ...field,
      details: {
        ...details,
        options: reorderedOptions
      }
    });
  }

  const changeOption = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = e.target.value;
    let newOptions = [...options];
    newOptions[index] = value;
    setField({
      ...field,
      details: {
        ...details,
        options: newOptions
      }
    });
  }

  const deleteOption = (index: number) => {
    let newOptions = [...options];
    newOptions.splice(index, 1);
    setField({
      ...field,
      details: {
        ...details,
        options: newOptions
      }
    });
  }

  const changeNewOption = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNewOption(value);
  }

  const handleOtherOption = (e: any) => {
    setField({
      ...field,
      details: {
        ...details,
        hasOtherOption: !hasOtherOption
      }
    })
  }

  useEffect(() => {
    if (newOption && newOption !== '') {
      let newOptions = [...options];
      newOptions.push(newOption);
      setField({
        ...field,
        details: {
          ...details,
          options: newOptions
        }
      });
      setNewOption('');
      setAddedNew(true);
    }
    // eslint-disable-next-line
  }, [newOption, field, details, setField]);

  useEffect(() => {
    setAddedNew(false);
  }, [selected, answerable]);

  const setValue = (value: string) => {
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable && required && value === ''
      },
      value
    });
  }

  const checkErrors = (e: any) => {
    if (!answerable) return;
    if (required) {
      if (field.value === '' || field.value === null) {
        setField({
          ...field,
          errors: {
            required: true
          }
        });
      }
    }
  }

  // const requiredError = field.errors && field.errors.required;
  const hasOtherResponse = options.indexOf(field.value || '') === -1 && (field.value !== null);

  if ((!selected) || answerable) return (
    <FormGroup>
      <Typography variant="h6">{fieldNumber + 1}. {title} {required && <b>*</b>}</Typography>
      <Typography variant="body2" style={{ marginTop: -8, marginBottom: 8, opacity: 0.8 }}>{description}</Typography>
      <RadioGroup name={`radio-${index}`} onChange={e => setValue(e.target.value)} onBlur={checkErrors}>
        {options.map((option, i) =>
          <FormControlLabel key={i} value={option} control={<Radio checked={field.value === option} disabled={formDisabled} />} label={option} />
        )}
      </RadioGroup>
      {hasOtherOption &&
        <FormControl>
          <Grid item container spacing={0}>
            <Grid item>
              <FormControlLabel
                control={<Radio checked={hasOtherResponse} onClick={() => setValue('')} />}
                label={''}
              />
            </Grid>
            <Grid item xs sx={{marginLeft: '-16px'}}>
              <TextField
                placeholder="Other..."
                disabled={formDisabled}
                value={hasOtherResponse ? field.value : ''}
                onChange={(e) => setValue(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </FormControl>}
    </FormGroup>
  );

  return (
    // editor part
    <FormGroup>
      <Grid item container spacing={2}>
        <Grid item>
          <Typography variant="h6">Options</Typography>
        </Grid>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Grid item container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
              {options.map((option, optionIndex) => (
                <Draggable key={`radio-${index}-option-${optionIndex}`} draggableId={`radio-${index}-option-${optionIndex}`} index={optionIndex}>
                  {(provided) => (
                    <Grid item container alignItems={'center'} spacing={2} {...provided.draggableProps} ref={provided.innerRef}>
                      <Grid item xs>
                        <TextField
                          value={option}
                          onChange={(e) => changeOption(e, optionIndex)}
                          disabled={formDisabled}
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="error" onClick={() => deleteOption(optionIndex)}>Delete</Button>
                      </Grid>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

        <Grid item xs={12}>
          <TextField placeholder="Add new option" value={newOption} onChange={changeNewOption} disabled={formDisabled} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasOtherOption}
                onChange={handleOtherOption}
                disabled={formDisabled}
              />
            }
            label="Show 'Other' option"
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default MultipleChoice;
