import Typography from "@mui/material/Typography";
import * as React from "react";

function SectionHeader({ header} : { header: string}) {
  return (
    <Typography variant={"overline"}>
      {header}
    </Typography>
  )
}

export default SectionHeader
