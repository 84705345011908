import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Grid, FormGroup, Checkbox, FormControlLabel, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { reorder } from '../../../../utils/utils';
import FormHelperText from "@mui/material/FormHelperText";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface Field {
  title: string;
  description: string;
  index: number;
  details: {
    columns: { id: string; value: string }[];
    rows: { id: string; value: string }[];
  };
  required: boolean;
  value: { id: string; value: string[] }[];
  errors?: {
    required: boolean;
  };
}

interface CheckboxGridProps {
  field: Field;
  setField: (field: Field) => void;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  fieldNumber: number;
}

const CheckboxGrid: React.FC<CheckboxGridProps> = (props) => {
  const { field, setField, selected, answerable, formDisabled, fieldNumber } = props;
  const { title, description, index, details, required } = field;
  const { columns, rows } = details;

  const [newOptionRow, setNewOptionRow] = useState('');
  const [newOptionColumn, setNewOptionColumn] = useState('');

  const [addedNew, setAddedNew] = useState(false);

  // Handle re-ordering on dnd
  const onDragEnd = (result: any, type: 'rows' | 'columns') => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setField({
      ...field,
      details: {
        ...details,
        [type]: reorder(
          details[type],
          result.source.index,
          result.destination.index
        ),
      },
    });
  };

  // Helper to set new options
  const setOptionsRow = (newOptions: { id: string; value: string }[]) => {
    setField({
      ...field,
      details: {
        ...details,
        rows: [...newOptions],
      },
    });
  };

  // Helper to set new options
  const setOptionsColumn = (newOptions: { id: string; value: string }[]) => {
    setField({
      ...field,
      details: {
        ...details,
        columns: [...newOptions],
      },
    });
  };

  // Handle change
  const changeOptionRow = (e: any, index: number) => {
    const value = e.target.value;
    let newOptions = [...rows];
    newOptions[index] = {
      id: newOptions[index].id,
      value,
    };
    setOptionsRow(newOptions);
  };

  // Handle change
  const changeOptionColumn = (e: any, index: number) => {
    const value = e.target.value;
    let newOptions = [...columns];
    newOptions[index] = {
      id: newOptions[index].id,
      value,
    };
    setOptionsColumn(newOptions);
  };

  // Handle deletion
  const deleteOptionRow = (index: number) => {
    let newOptions = [...rows];
    newOptions.splice(index, 1);
    setOptionsRow(newOptions);
  };

  // Handle deletion
  const deleteOptionColumn = (index: number) => {
    let newOptions = [...columns];
    newOptions.splice(index, 1);
    setOptionsColumn(newOptions);
  };

  // Handle addition
  const changeNewOption = (e: any, type: 'row' | 'column') => {
    const value = e.target.value;
    if (type === 'column') {
      setNewOptionColumn(value);
    } else {
      setNewOptionRow(value);
    }
  };

  // Create new entry on change
  useEffect(() => {
    if (newOptionRow && newOptionRow !== '') {
      let newOptionRows = [...rows];
      newOptionRows.push({
        id: `option-row-${new Date().valueOf()}`,
        value: newOptionRow,
      });
      setOptionsRow(newOptionRows);
      setNewOptionRow('');
      setAddedNew(true);
    }
    // eslint-disable-next-line
  }, [newOptionRow]);

  // Create new entry on change
  useEffect(() => {
    if (newOptionColumn && newOptionColumn !== '') {
      let newOptionColumns = [...columns];
      newOptionColumns.push({
        id: `option-column-${new Date().valueOf()}`,
        value: newOptionColumn,
      });
      setOptionsColumn(newOptionColumns);
      setNewOptionColumn('');
      setAddedNew(true);
    }
    // eslint-disable-next-line
  }, [newOptionColumn]);

  // Blur when previewing/editing
  useEffect(() => {
    setAddedNew(false);
  }, [selected, answerable]);

  const setValue = (row: { id: string; value: string }, column: { id: string; value: string }) => {
    const newValues = [...field.value];
    const rowObjIndex = newValues.findIndex((r) => r.id === row.id);

    if (rowObjIndex !== -1) {
      const columnIndex = newValues[rowObjIndex].value.findIndex((c) => c === column.id);

      if (columnIndex !== -1) {
        if (newValues[rowObjIndex].value.length === 1) {
          newValues.splice(rowObjIndex, 1);
        } else {
          newValues[rowObjIndex].value.splice(columnIndex, 1);
        }
      } else {
        newValues[rowObjIndex].value.push(column.id);
      }
    } else {
      newValues.push({
        id: row.id,
        value: [column.id],
      });
    }

    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable && required && newValues.length === 0,
      },
      value: newValues,
    });
  };

  const checkErrors = (e: any) => {
    if (!answerable) return;
    if (required) {
      if (field.value.length === 0) {
        setField({
          ...field,
          errors: {
            required: true,
          },
        });
      }
    }
  };

  const requiredError = field.errors && field.errors.required;
  const hasError = requiredError;

  // The view / preview part
  //////////////////////////
  if (!selected || answerable)
    return (
      <>
        <Typography variant="h6">{fieldNumber + 1}. {title} {required && <b>*</b>}</Typography>

        <Typography variant="body2" style={{ marginTop: -8, marginBottom: 8, opacity: 0.8 }}>
          {description}
        </Typography>

        <Grid container>
          <Grid item lg={12}>
            <div className="">
              <div className="table-responsive">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> &nbsp; </TableCell>
                      {details.columns.map((column, i) => (
                        <TableCell key={i}>{column.value}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {details.rows.map((row, x) => {
                      return (
                        <TableRow key={x}>
                          <TableCell>{row.value}</TableCell>
                          {details.columns.map((column, y) => {
                            const checked = field.value ? field.value.find((r) => r.id === row.id && r.value.includes(column.id)) : false;

                            return (
                              <TableCell key={y} align="center">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={`radio-${index}-${x}-${y}`}
                                        id={`radio-${index}-${x}-${y}`}
                                        disabled={formDisabled}
                                        checked={Boolean(checked)}
                                        onChange={() => setValue(row, column)}
                                        onBlur={checkErrors}
                                        // error={hasError}
                                      />
                                    }
                                    label=""
                                  />
                                  {hasError && <FormHelperText>You must check this box</FormHelperText>}
                                </FormGroup>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );

  // The editor part
  ///////////////////
  return (
    <Grid container spacing={2}>

      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Rows</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Columns</Typography>
        </Grid>
      </Grid>

      <Grid item xs={6}>

        <DragDropContext onDragEnd={(r) => onDragEnd(r, 'rows')}>
          <Droppable droppableId="droppable-rows">
            {(provided) => (
              <Grid item container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                {details.rows.map((option, optionIndex) => {
                  const shouldFocus = addedNew && optionIndex === details.rows.length - 1;

                  return (
                    <Draggable key={`radio-${index}-row-${optionIndex}`} draggableId={`radio-${index}-row-${optionIndex}`} index={optionIndex}>
                      {(provided) => (
                        <Grid item container spacing={2} {...provided.draggableProps} ref={provided.innerRef} alignItems="center">
                          <Grid item xs={1} {...provided.dragHandleProps} style={{ color: '#5c5c5c', fontSize: 18 }}>
                            <DragIndicatorIcon />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              type="text"
                              name={`radio-${index}-row-${optionIndex}-label`}
                              id={`radio-${index}-row-${optionIndex}-label`}
                              placeholder={'Label'}
                              value={option.value}
                              onChange={(e) => changeOptionRow(e, optionIndex)}
                              autoFocus={shouldFocus}
                              disabled={formDisabled}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Button variant="contained" color="error" onClick={() => deleteOptionRow(optionIndex)}>Delete</Button>
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>

        <TextField
          type="text"
          name={`new-row-option`}
          id={`new-row-option`}
          placeholder={'Add new option'}
          value={newOptionRow}
          onChange={(e) => changeNewOption(e, 'row')}
          disabled={formDisabled}
          variant={'outlined'}
          fullWidth
          style={{ marginTop: 16 }}
        />
      </Grid>

      <Grid item xs={6}>

        <DragDropContext onDragEnd={(r) => onDragEnd(r, 'columns')}>
          <Droppable droppableId="droppable-columns">
            {(provided) => (
              <Grid item container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                {details.columns.map((option, optionIndex) => {
                  const shouldFocus = addedNew && optionIndex === details.columns.length - 1;

                  return (
                    <Draggable key={`radio-${index}-column-${optionIndex}`} draggableId={`radio-${index}-column-${optionIndex}`} index={optionIndex}>
                      {(provided) => (
                        <Grid item container spacing={2} {...provided.draggableProps} ref={provided.innerRef} alignItems="center">
                          <Grid item xs={1} {...provided.dragHandleProps} style={{ color: '#5c5c5c', fontSize: 18 }}>
                            <DragIndicatorIcon />
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              type="text"
                              name={`radio-${index}-column-${optionIndex}-label`}
                              id={`radio-${index}-column-${optionIndex}-label`}
                              placeholder={'Label'}
                              value={option.value}
                              onChange={(e) => changeOptionColumn(e, optionIndex)}
                              autoFocus={shouldFocus}
                              disabled={formDisabled}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Button variant="contained" color="error" onClick={() => deleteOptionColumn(optionIndex)}>Delete</Button>
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>

        <TextField
          type="text"
          name={`new-column-option`}
          id={`new-column-option`}
          placeholder={'Add new option'}
          value={newOptionColumn}
          onChange={(e) => changeNewOption(e, 'column')}
          disabled={formDisabled}
          fullWidth
          style={{ marginTop: 16 }}
        />
      </Grid>
    </Grid>
  );
};

export default CheckboxGrid;
