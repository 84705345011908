import { getToken } from "../utils/utils";
import { Navigate } from "react-router-dom";
import * as React from "react";
import {useIAMPermission} from "../store/userStore";
import IAMPermission from "../models/IAMPermission";

const withAuth = (WrappedComponent: () => JSX.Element, somePermissions?: IAMPermission[]) => {
  function Authenticator(props: any) {
    const token = getToken();

    const iam = useIAMPermission()

    if (!token) {
      return <Navigate to="/login" replace />;
    }

    if (somePermissions) {
      const isAllowed = iam.hasSome(somePermissions)
      if (!isAllowed) {
        return <Navigate to="/" replace />;
      }
    }


    return <WrappedComponent {...props} />;
  }

  return Authenticator;
};

export default withAuth;
