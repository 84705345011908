// ----------------------------------------------------------------------

export default function ButtonBase() {
	return {
		MuiButtonBase: {
			defaultProps: {
				// The props to change the default for.
				disableRipple: true, // No more ripple!
			}
		},
	};
}
