import {MediaAsset} from "../../../models/MediaAsset";
import {useCallback, useState} from "react";

const useImageDownload = (asset: MediaAsset): [() => void, boolean] => {
  const [downloading, setDownloading] = useState(false)

  const downloadImage = useCallback(async () => {
    setDownloading(true)
    try {
      const imageUrl = asset.bitstreamFile.viewUrl
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Create a temporary link and set the Blob as its source
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);

      // Set the filename of the downloaded image
      link.download = asset.label

      // Trigger the download
      link.click();

      // Clean up the temporary link
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Failed to download image:', error);
    }
    setDownloading(false)
  }, [asset.bitstreamFile.viewUrl, asset.label]);

  return [downloadImage, downloading]
}

export default useImageDownload