import React from 'react';
import MultipleChoicesGrid from "../common/MultipleChoicesGrid";

const MultipleChoiceGrid = (props:any) => {
  const {field, fieldNumber} = props

  return <MultipleChoicesGrid field={field} fieldNumber={fieldNumber} />
}

export default MultipleChoiceGrid;
