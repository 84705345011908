import {useCallback, useRef, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateQueryStringParameter } from "./useQuery";

export const useSearchQuery = (): [string, (v: string) => void] => {
  const [query, setQuery] = useState("");

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const ref = useRef(undefined)

  const handleChange = useCallback((value: string) => {

      clearTimeout(ref.current)
      setQuery(value);

      // @ts-ignore
      ref.current = setTimeout(() => {
        const newSearch = updateQueryStringParameter(search, "q", value);
        const newSearch2 = updateQueryStringParameter(newSearch, "page", "0");
        navigate(`${pathname}/${newSearch2}`);
      }, 500);

    },
    [navigate, pathname, search]
  );

  return [query, handleChange];
};
