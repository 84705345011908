import {useUIStore} from "../../store/uiStore";
import {useEffect, useState} from "react";

const useCollapsedLayout = () => {
  const { drawerOpen, toggleLayout } = useUIStore()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    setInitialized(true)
    if (drawerOpen && !initialized) {
      toggleLayout()
    }
  }, [drawerOpen, initialized, toggleLayout])
}

export default useCollapsedLayout