import * as React from "react";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";

type EmptyApplicationsProps = {
  handleCreateNew: () => void;
};

function EmptySurveys({ handleCreateNew }: EmptyApplicationsProps) {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      display={"flex"}
      direction={"column"}
    >
      <PollOutlinedIcon sx={{ fontSize: "16rem", color: "#c4c4c4" }} />
      {t("surveys.noneYet")}
      <Button startIcon={<AddIcon />} onClick={handleCreateNew}>
        {t("surveys.createNewButton")}
      </Button>
    </Stack>
  );
}

export default EmptySurveys;
