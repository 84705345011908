import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import Fab from "../../../components/Common/Fab";
import withAuth from "../../../auth/withAuth";
import { useSearchQuery } from "../../../hooks/query/useSearchQuery";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import PaginationControl from "../../../pages/Content/Containers/Common/PaginationControl";
import { useListView } from "../../../hooks/common/useListView";
import { useSortHandlerWithRouter } from "../../../hooks/sort/useSortHandlerWithRouter";
import { Link, useNavigate } from "react-router-dom";
import { usePaginatedEntity } from "../../../store/core";
import { API_URL } from "../../../utils/config";
import SettingsContainer from "../../../components/Common/SettingsContainer";
import SurveysContentHeader from "../components/SurveysContentHeader";
import EmptySurveys from "./emptySurveys";
import SurveyItem from "../models/SurveyItem";
import SurveyItemCard from "../components/SurveyItemCard";
import SurveyItemsListView from "./SurveysItemListView";
import mockData from "../mockData/srv.surveys.json";

const Surveys = () => {
  const navigate = useNavigate();
  const { setPage, page, results, getAll } =
    usePaginatedEntity<SurveyItem>(`${API_URL}/srv/surveys`, "uuid", undefined, mockData);
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useSearchQuery();
  const listViewHandler = useListView("listView");
  const sortHandler = useSortHandlerWithRouter();

  const listView = listViewHandler.listView;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getAll();
      setLoading(false);
    })();
  }, [getAll]);

  const handleRefresh = async () => {
    await getAll();
  };

  const onCreate = () => {
    setLoading(true);
    handleRefresh().then(() => setLoading(false));
  };

  const handleListItemClick = (event: React.MouseEvent<HTMLElement>) => {
    navigate(`/surveys/${event.currentTarget.id}`);
  };

  const hasResults = Boolean(results?.content?.length !== 0);

  const handleCreateNew = () => {
    onCreate();
  }

  return (
    <SettingsContainer>
      <SurveysContentHeader
        title={"Surveys"}
        query={query}
        setQuery={setQuery}
        listViewHandler={listViewHandler}
        sortHandler={sortHandler}
        sortOptions={[
          { label: "myContent.sortBy.label", by: "label" },
          { label: "myContent.sortBy.createdAt", by: "createdAt" },
          { label: "myContent.sortBy.updatedAt", by: "updatedAt" },
        ]}
      />

      {loading ? (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <CircularProgress />
        </Stack>
      ) : (
        <Fragment>
          <Box mt={2} />

          {!listView && hasResults && (
            <Grid container spacing={2}>
              {results.content.map(
                (item, i: React.Key | null | undefined) => (
                  <Grid item key={i} xl={3} md={4} sm={6} xs={12}>
                    <Link
                      to={`/surveys/${item.uuid}`}
                      style={{ textDecoration: "none" }}
                    >
                      <SurveyItemCard item={item} />
                    </Link>
                  </Grid>
                )
              )}
            </Grid>
          )}

          {Boolean(listView) && hasResults && (
            <SurveyItemsListView
              items={results.content}
              selectedItemUUID={""}
              handleOnClick={handleListItemClick}
            />
          )}

          {(!results || !hasResults) && !Boolean(query) && (
            <EmptySurveys handleCreateNew={handleCreateNew} />
          )}

          <Fab onClick={handleCreateNew} />

          {hasResults && (
            <Stack direction={"row"} justifyContent={"center"} mt={2}>
              <PaginationControl
                disabled={false}
                count={results.totalPages}
                page={page}
                onPageChange={setPage}
              />
            </Stack>
          )}
        </Fragment>
      )}
    </SettingsContainer>
  );
};

export default withAuth(Surveys);
