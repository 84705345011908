class FeedBack {
  uuid: string = "";
  subject: string = "";
  message: string = "";
  createdAt: string = "";
  createdBy: User = {};
}

type User = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};


export default FeedBack;