import React from "react";
import { Box, Typography } from "@mui/material";
//import { Bar } from "react-chartjs-2";
import { GRAPH_COLORS } from "../../../components/consts";
//import { CategoryScale, Chart, LinearScale } from "chart.js";

interface Row {
  label: string;
}

interface Column {
  label: string;
  data: number[];
}

interface AnswerSummary {
  rows: Row[];
  columns: Column[];
}

interface Field {
  questionTitle: string;
  answerCount: number;
  answerSummary: AnswerSummary;
}

interface MultipleChoicesGridProps {
  field: Field;
  fieldNumber: number;
}

const MultipleChoicesGrid: React.FC<MultipleChoicesGridProps> = ({ field, fieldNumber }) => {
  const { questionTitle, answerCount, answerSummary } = field;

  // Chart.register(CategoryScale);
  // Chart.register(LinearScale);

  const optionsLabels = answerSummary.rows.map((o) => o.label);
  const optionsData = answerSummary.columns.map((o, i) => {
    return {
      label: o.label,
      data: o.data,
      backgroundColor: GRAPH_COLORS[i]
    };
  });

  const data = {
    labels: optionsLabels,
    datasets: optionsData
  };

  // @ts-ignore
  const optionsBar = {
    // barValueSpacing: 20,
    // scales: {
    //   xAxes: [
    //     {
    //       ticks: {
    //         stepSize: 1,
    //         callback: function (value: string | number) {
    //           return truncateString(value.toString(), 20);
    //         }
    //       }
    //     }
    //   ],
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //         stepSize: 1
    //       },
    //     },
    //   ],
    // },
    barValueSpacing: 20,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 1
        }
      },
      x: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          // @ts-ignore
          callback: function(value: number) {
            // truncate the labels only in this axis
            // @ts-ignore
            const lbl = this.getLabelForValue(value as number);
            if (typeof lbl === "string" && lbl.length > 10) {
              return `${lbl.substring(0, 10)}...`;
            }
            return lbl;
          }
        }
      }
    }
  };

  return (
    <Box sx={{ minHeight: 440 }}>
      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
        {fieldNumber + 1}. {questionTitle}
      </Typography>
      <Typography variant="body1" mb={1}>
        {answerCount} responses
      </Typography>

      {/*<Box>*/}
      {/*  <Bar width={50}*/}
      {/*       height={20}*/}
      {/*       data={data}*/}
      {/*    // @ts-ignore*/}
      {/*       options={optionsBar} />*/}
      {/*</Box>*/}
    </Box>
  );
};

export default MultipleChoicesGrid;
