import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Checkbox, FormControlLabel, FormGroup, TextField, Typography, Button, Grid } from '@mui/material';
import { reorder } from '../../../../utils/utils';
import FormHelperText from "@mui/material/FormHelperText";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface Field {
  title: string;
  description: string;
  index: number;
  required: boolean;
  details: {
    options: string[];
    hasOtherOption: boolean;
  };
  value: {
    answers: string[];
    other: string | null;
  };
  errors?: {
    required: boolean;
  };
}

interface CheckboxesProps {
  field: Field;
  setField: (field: Field) => void;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  fieldNumber: number;
}

const Checkboxes: React.FC<CheckboxesProps> = (props) => {
  const { field, setField, selected, answerable, formDisabled, fieldNumber } = props;
  const { title, description, index, required, details } = field;
  const { options, hasOtherOption } = details;

  const [newOption, setNewOption] = useState('');
  const [addedNew, setAddedNew] = useState(false);

  // Handle re-ordering on dnd
  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    setField({
      ...field,
      details: {
        ...details,
        options: reorder(
          options,
          result.source.index,
          result.destination.index
        ),
      },
    });
  };

  // Helper to set new options
  const setOptions = (newOptions: string[]) => {
    setField({
      ...field,
      details: {
        ...details,
        options: newOptions,
      },
    });
  };

  // Handle change
  const changeOption = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = e.target.value;
    let newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  // Handle deletion
  const deleteOption = (index: number) => {
    let newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  // Handle addition
  const changeNewOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewOption(value);
  };

  // Create new entry on change
  useEffect(() => {
    if (newOption && newOption !== '') {
      let newOptions = [...options];
      newOptions.push(newOption);
      setOptions(newOptions);
      setNewOption('');
      setAddedNew(true);
    }
    // eslint-disable-next-line
  }, [newOption]);

  // Blur when previewing/editing
  useEffect(() => {
    setAddedNew(false);
  }, [selected, answerable]);

  // Handle "other" option
  const handleOtherOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setField({
      ...field,
      details: {
        ...details,
        hasOtherOption: !hasOtherOption,
      },
    });
  };

  const setValue = (optionValue: string) => {
    const newValue = [...field.value.answers];

    // if it exists, remove from selected
    if (field.value.answers.includes(optionValue)) {
      const optionIndex = field.value.answers.indexOf(optionValue);
      newValue.splice(optionIndex, 1);
    } else {
      // otherwise add
      newValue.push(optionValue);
    }

    // update field
    // @ts-ignore
    setField({
      ...field,
      errors: {
        required: answerable && field.value.answers.length === 0 && !!newValue,
      },
      value: {
        ...field.value,
        answers: newValue,
      },
    });
  };

  const setOther = (newValue: string) => {
    // update field other
    setField({
      ...field,
      errors: {
        required: answerable && field.value.answers.length === 0 && !!newValue,
      },
      value: {
        ...field.value,
        other: newValue ? newValue : null,
      },
    });
  };

  const checkErrors = (e: any) => {
    if (!answerable) return;
    if (required) {
      if (field.value.answers.length === 0) {
        setField({
          ...field,
          errors: {
            required: true,
          },
        });
      }
    }
  };

  // Initialize / fallback for old type of value
  useEffect(() => {
    if (!field.value.answers) {
      // if (field.value.answers.length) {
      //   setField({ ...field, value: { answers: field.value.answers, other: null } });
      // } else {
        setField({ ...field, value: { answers: [], other: null } });
      // }
    }
    // eslint-disable-next-line
  }, [field]);

  const requiredError = field.errors && field.errors.required;
  const hasError = requiredError;

  const ViewPreview = () => (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">{fieldNumber + 1}. {title} {required && <b>*</b>}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" style={{ marginTop: -8, marginBottom: 8, opacity: 0.8 }}>
          {description}
        </Typography>
      </Grid>
      {options.map((option, i) => (
        <Grid item key={i}>
          <FormControlLabel
            control={
              <Checkbox
                name={`checkboxes-${index}-option-${i}`}
                id={`checkboxes-${index}-option-${i}`}
                checked={field.value.answers && field.value.answers.includes(option)}
                value={option}
                onChange={() => setValue(option)}
                onBlur={checkErrors}
                // error={Boolean(hasError)}
              />
            }
            label={option}
          />
          {hasError && <FormHelperText>Error</FormHelperText>}
        </Grid>
      ))}
      {hasOtherOption && (
        <Grid item xs={12}>
          <FormGroup>
            <Grid container alignItems="center">
              <Grid item sx={{padding:0, ml: -1}}>
                <Checkbox
                  name={`radio-${index}`}
                  id={`radio-${index}`}
                  checked={!!field.value.other}
                  onClick={() => setField({ ...field, value: { ...field.value, other: null } })}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  type="text"
                  name={`radio-other-${index}`}
                  id={`radio-other-${index}`}
                  placeholder={'Other...'}
                  disabled={formDisabled}
                  onBlur={checkErrors}
                  error={hasError}
                  value={field.value.other ? field.value.other : ''}
                  onChange={(e) => setOther(e.target.value)}
                  variant={'outlined'}
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
      )}
    </Grid>
  );

  const EditorView = () => (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Options</Typography>
      </Grid>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Grid item container {...provided.droppableProps} ref={provided.innerRef} spacing={2}>
              {options.map((option, optionIndex) => {
                const shouldFocus = addedNew && optionIndex === options.length - 1;

                return (
                  <Draggable key={`checkboxes-${index}-option-${optionIndex}`} draggableId={`checkboxes-${index}-option-${optionIndex}`} index={optionIndex}>
                    {(provided) => (
                      <Grid item xs={12} {...provided.draggableProps} ref={provided.innerRef}>
                        <Grid item spacing={2} container justifyContent="space-between" alignItems="center">
                          <Grid item {...provided.dragHandleProps} style={{ color: '#5c5c5c', fontSize: 18 }}>
                            <DragIndicatorIcon />
                          </Grid>
                          <Grid item xs>
                            <TextField
                              type="text"
                              name={`checkboxes-${index}-option-${optionIndex}-label`}
                              id={`checkboxes-${index}-option-${optionIndex}-label`}
                              placeholder={'Label'}
                              value={option}
                              onChange={(e) => changeOption(e, optionIndex)}
                              autoFocus={shouldFocus}
                              disabled={formDisabled}
                              fullWidth
                            />
                          </Grid>
                          <Grid item>
                            <Button variant="contained" color="error" onClick={() => deleteOption(optionIndex)}>Delete</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      <Grid item xs={12}>
        <TextField
          type="text"
          name="new-option"
          id="new-option"
          placeholder="Add new option"
          value={newOption}
          onChange={changeNewOption}
          fullWidth
        />
      </Grid>

      <Grid item>
        <Grid item container justifyContent="flex-end">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  name={`multiplechoice-${index}-other`}
                  id={`multiplechoice-${index}-other`}
                  checked={hasOtherOption}
                  onChange={handleOtherOption}
                  disabled={formDisabled}
                />
              }
              label="Show 'Other' option"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  // The view / preview part
  if (!selected || answerable)
    return <ViewPreview />;

// The editor part/
  return <EditorView />;
};

export default Checkboxes;
