import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";
import { ActualDate } from "utils/utils";

interface Geometry {
  uuid: string;
  label: string;
  createdAt: string;
}

type GlobalSearchPopperProps = {
  options: Geometry[];
  handleClose: () => void;
};

const GeoSearchPopover = ({
  handleClose,
  options,
}: GlobalSearchPopperProps) => {
  return (
    <Paper
      sx={{
        width: "600px",
        height: "auto",
        overflow: "hidden",
      }}
      elevation={6}
    >
      <MenuList
        sx={{
          maxHeight: "320px",
        }}
      >
        {options.map((item) => (
          <Link
            key={item.uuid}
            to={`/geo?featureId=${item.uuid}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <MenuItem id={item.uuid} onClick={handleClose}>
              <Stack
                flexDirection={"row"}
                columnGap={4}
                alignItems={"center"}
                flexGrow={1}
                py={1}
              >
                <Stack
                  flexDirection={"column"}
                  rowGap={0.5}
                  sx={{
                    width: "350px",
                  }}
                >
                  <Typography noWrap variant={"body1"}>
                    {item.label}
                  </Typography>
                </Stack>
                <div style={{ flexGrow: 1 }}></div>
                <Typography variant={"body2"}>
                  {ActualDate(item.createdAt)}
                </Typography>
              </Stack>
            </MenuItem>
          </Link>
        ))}

        {options.length === 0 && (
          <MenuItem disabled={true}>
            <ListItemText sx={{ textAlign: "center" }}>
              <Typography variant={"body1"}>No results</Typography>
            </ListItemText>
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  );
};

export default GeoSearchPopover;
