import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

interface AssetHelperTextProps {
  helperText?: string | React.ReactNode;
  maxFiles?: number;
  currentFileCount: number;
  error?: boolean
}

function AssetHelperText({ helperText, maxFiles, currentFileCount, error }:AssetHelperTextProps) {

  if (!(Boolean(helperText) || Boolean(maxFiles && maxFiles !== 1))) return <> </>

  return (
      <Stack
        justifyContent={"space-between"}
        flexWrap={"nowrap"}
        alignItems={"center"}
        direction={"row"}
      >
        <Typography ml={2} color={error ? "error" : "textSecondary"} variant={"caption"}>
          {helperText}
        </Typography>
        {maxFiles && maxFiles !== 1 && (
          <Typography color={"textSecondary"} variant={"caption"}>
            {currentFileCount}/{maxFiles}
          </Typography>
        )}
      </Stack>
  );
}

export default AssetHelperText
