import React, { ChangeEvent, FocusEvent } from 'react';
import { FormGroup, FormControl, InputLabel, TextField, Typography } from "@mui/material";

interface Field {
  title: string;
  description: string;
  index: number;
  required: boolean;
  value: string;
  errors?: {
    required?: boolean;
  }
}

interface DateSelectProps {
  field: Field;
  setField: (field: Field) => void;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  fieldNumber: number;
}

const DateSelect: React.FC<DateSelectProps> = (props) => {
  const { field, setField, selected, answerable, formDisabled, fieldNumber } = props;
  const { title, description, index, required } = field;

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable && required && value === '',
      },
      value,
    });
  };

  const checkErrors = (e: FocusEvent<HTMLInputElement>) => {
    if (!answerable) return;
    if (required) {
      if (field.value === '' || field.value === null) {
        setField({
          ...field,
          errors: {
            required: true,
          },
        });
      }
    }
  };

  const requiredError = field.errors && field.errors.required;
  const hasError = !!requiredError;

  if ((!selected) || answerable) return (
    <FormGroup row>
      <FormControl>
        <InputLabel htmlFor={`date-${index}`}>{fieldNumber + 1}. {title} {required && <b>*</b>}</InputLabel>
        <Typography variant="caption">{description}</Typography>
        <TextField
          type='date'
          name={`date-${index}`}
          id={`date-${index}`}
          placeholder={description}
          disabled={formDisabled}
          onChange={setValue}
          onBlur={checkErrors}
          error={hasError}
          value={field.value || ""}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    </FormGroup>
  );

  return null;
}

export default DateSelect;
