import React from 'react';
import ListOfText from "../common/ListOfText";
import { Grid } from "@mui/material";

const Paragraph = (props:any) => {
  const {field, fieldNumber} = props

  return <>
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <ListOfText field={field} fieldNumber={fieldNumber}/>
      </Grid>
    </Grid>
  </>
}

export default Paragraph;
