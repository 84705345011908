import { Fab as MuiFab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

type FabProps = {
  onClick: () => void;
  disabled?: boolean;
};

function Fab({ onClick, disabled }: FabProps) {
  return (
    <MuiFab
      id={"create-btn"}
      onClick={onClick}
      disabled={disabled}
      color="primary"
      aria-label="add"
      style={{
        position: "fixed",
        bottom: 0,
        right: 16,
        margin: 16
      }}
    >
      <AddIcon />
    </MuiFab>
  );
}

export default Fab;
