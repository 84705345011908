type useHasPreviewOutput = {
  hasPreview: boolean;
  deleted: boolean;
};

export const useHasPreview = (
  fileType: string | null,
  filename: string | null
): useHasPreviewOutput => {
  const deleted = !fileType;
  const hasPreview = !!(
    fileType &&
    (fileType.includes("image/png") ||
      fileType.includes("image/jpeg") ||
      fileType.includes("image/gif") ||
      fileType.includes("image/tiff") ||
      fileType.includes("image/bmp") ||
      fileType.includes("video/mp4") ||
      fileType.includes("video/webm") ||
      fileType.includes("audio") ||
      fileType.includes("pdf") ||
      fileType.includes("wordprocessingml") ||
      fileType.includes("msword") ||
      fileType.includes("spreadsheetml") ||
      fileType.includes("ms-excel") ||
      fileType.includes("ms-powerpoint") ||
      fileType.includes("presentationml") ||
      fileType.includes("text/plain") ||
      (fileType.includes("text/xml") || fileType.includes("application/xslt+xml")) ||
      ((fileType.includes("octet-stream") || fileType.includes("sla")) &&
        filename?.includes("stl")) ||
      ((fileType.includes("octet-stream") || fileType.includes("model/obj")) &&
        filename?.includes("obj")) ||
      (fileType.includes("octet-stream") && filename?.includes("dae"))||
      (fileType.includes("octet-stream") && filename?.includes("glb")))
  );

  return { hasPreview, deleted };
};
