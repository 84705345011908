import { usePlacesWidget } from "react-google-autocomplete";
import { TextFieldVariants } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";

type PlacesAutocompleteProps = {
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  countryRestrictions?: string[];
  variant?: TextFieldVariants | undefined;
  size?: "small" | "medium" | undefined;
};

const PlacesAutocomplete = ({
  onPlaceSelected,
  countryRestrictions,
  variant='standard',
  size='small'
}: PlacesAutocompleteProps) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) => {
      onPlaceSelected(place);
    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: countryRestrictions || [] },
    },
  });

  return (
    <TextField fullWidth inputRef={ref} variant={variant} size={size} />
  );
};

export default PlacesAutocomplete;
