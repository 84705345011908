import * as React from "react";
import {useInitialSort, useSortHandlerParam} from "./useSortHandlerParam";
import {useSortHandler} from "./useSortHandler";

type Sort = {
  direction: string,
  by: string
}
export type SortHandler = {
  sort: Sort,
  setSort: React.Dispatch<React.SetStateAction<Sort>>,
  handleSort: (by: string) => void
}

export const useSortHandlerWithRouter = (): SortHandler => {
  const initialSort = useInitialSort()
  const sortHandler = useSortHandler(initialSort.by, initialSort.direction);
  useSortHandlerParam(sortHandler.sort)

  return sortHandler
};
