import Pagination from "@mui/material/Pagination";
import React from "react";

type PaginationControlProps = {
  count: number;
  page: number;
  disabled: boolean;
  onPageChange: (page: number) => void;
  size?: string;
};

const PaginationControl = React.memo(function PaginationControl(props: PaginationControlProps) {
  const { page, count, onPageChange, disabled, size } = props;
  return (
    <Pagination
      // @ts-ignore
      size={size}
      disabled={disabled}
      page={page}
      onChange={(e, page) => onPageChange(page - 1)}
      count={count}
      variant="outlined"
      color="primary"
    />
  );
})

export default PaginationControl;
