// import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop(theme) {
  // const varLow = alpha(theme.palette.grey[900], 0.48);
  // const varHigh = alpha(theme.palette.grey[900], 1);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(6px)",
          backgroundColor: "rgba(255,255,255,0.1)",
          '&.MuiBackdrop-invisible': {
            backdropFilter: "none",
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
}
