import React, { ChangeEvent, FocusEvent } from 'react';
import { Grid, TextField, Typography } from "@mui/material";

export interface Field {
  [x: string]: string;
  title: string;
  description: string;
  value: any;
  required: any;
  index: any;
  errors?: any;
}

interface ShortAnswerProps {
  field: Field;
  setField: React.Dispatch<React.SetStateAction<Field>>;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  standalone: boolean;
  fieldNumber: number;
}

const ShortAnswer: React.FC<ShortAnswerProps> = (props) => {
  const {
    field,
    setField,
    selected,
    answerable,
    formDisabled,
    standalone,
    fieldNumber
  } = props;
  const { title, description, value, required, index } = field;

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // @ts-ignore
    setField((prevField) => ({
      ...prevField,
      errors: {
        ...prevField.errors,
        required: answerable && required && value === ''
      },
      value
    }));
  };

  const checkErrors = (e: FocusEvent<HTMLInputElement>) => {
    if (!answerable) return;
    if (required) {
      if (field.value === '' || field.value === null) {
        // @ts-ignore
        setField((prevField) => ({
          ...prevField,
          errors: {
            required: true
          }
        }));
      }
    }
  };

  const requiredError = field.errors && field.errors.required;
  const hasError = requiredError;

  if ((!selected) || answerable) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{!standalone ? `${fieldNumber + 1}. ` : ''}{title} {required && <b>*</b>}{' '}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name={`shortanswer-${index}`}
              id={`shortanswer-${index}`}
              placeholder={description}
              disabled={formDisabled}
              value={value}
              onChange={setValue}
              onBlur={checkErrors}
              error={hasError}
              fullWidth
              variant={'outlined'}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return null;
};

export default ShortAnswer;
