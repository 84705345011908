import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CollectionsIcon from "@mui/icons-material/Collections";

interface AssetAddButtonProps {
  maxFiles?: number;
  currentFileCount: number;
  onAddClick: () => void;
}

function AssetAddButton({ maxFiles, currentFileCount, onAddClick }:AssetAddButtonProps) {
  const { t } = useTranslation();

  return (
    <Stack>
      <Button
        sx={{ m: "auto", textTransform: "none" }}
        disabled={maxFiles === currentFileCount && maxFiles !== 1}
        startIcon={<CollectionsIcon />}
      onClick={onAddClick}>
      {(maxFiles === 1 && maxFiles === currentFileCount) ? t("assetpicker.changeAsset") : t("assetpicker.selectAsset")}
    </Button>
</Stack>
);
}

export default AssetAddButton
