import React, { ChangeEvent, FocusEvent } from 'react';
import { Grid, TextField, Typography } from "@mui/material";

interface Field {
  title: string;
  description: string;
  value: string;
  required: boolean;
  index: number;
  errors?: {
    required?: boolean;
  };
}

interface ParagraphProps {
  field: Field;
  setField: React.Dispatch<React.SetStateAction<Field>>;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  fieldNumber: number;
}

const Paragraph: React.FC<ParagraphProps> = (props) => {
  const {
    field,
    setField,
    selected,
    answerable,
    formDisabled,
    fieldNumber
  } = props;
  const { title, description, value, required, index } = field;

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setField((prevField) => ({
      ...prevField,
      errors: {
        ...prevField.errors,
        required: answerable && required && value === ''
      },
      value
    }));
  };

  const checkErrors = (e: FocusEvent<HTMLInputElement>) => {
    if (!answerable) return;
    if (required) {
      if (field.value === '' || field.value === null) {
        setField((prevField) => ({
          ...prevField,
          errors: {
            required: true
          }
        }));
      }
    }
  };

  const requiredError = field.errors && field.errors.required;
  const hasError = requiredError;

  if ((!selected) || answerable) {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">{fieldNumber + 1}. {title} {required && <b>*</b>}</Typography>

            <TextField
              multiline
              name={`paragraph-${index}`}
              id={`paragraph-${index}`}
              sx={{ minHeight: 130 }}
              placeholder={description}
              value={value}
              onChange={setValue}
              disabled={formDisabled}
              onBlur={checkErrors}
              error={hasError}
              fullWidth
              variant={'outlined'}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return null;
};

export default Paragraph;
