type Pageable = {
  page: number,
  size: number,
  sort: string[]
}

type Sort = {
  sorted: boolean,
  unsorted: boolean,
  empty: boolean
}

class Paging <T>{
  totalElements: number
  totalPages: number
  size: number
  number: number
  numberOfElements: number
  first: boolean
  last: boolean
  empty: boolean
  pageable: Pageable
  sort: Sort
  content: T[]

  constructor() {
    this.totalElements = 0;
    this.totalPages = 0;
    this.size = 0;
    this.number = 0;
    this.numberOfElements = 0;
    this.first = true;
    this.last = true;
    this.empty = true;
    this.pageable = {
      page: 0,
      size: 0,
      sort: [],
    };
    this.sort = {
      sorted: true,
      unsorted: true,
      empty: true,
    };
    this.content = [];
  }
}
export default Paging
