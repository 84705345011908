import MenuList from "@mui/material/MenuList";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Fragment, useMemo } from "react";
import { SortHandler } from "../../hooks/sort/useSortHandler";
import { useTranslation } from "react-i18next";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export type SortOption = {
  label: string;
  by: string;
};

type PopOverProps = {
  sortHandler: SortHandler;
  sortOptions: SortOption[];
};

function SortPopOver({ sortHandler, sortOptions }: PopOverProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedSortItem = useMemo(() => {
    return (
      sortOptions.find((x) => x.by === sortHandler.sort.by) ?? sortOptions[0]
    );
  }, [sortHandler, sortOptions]);

  const sortByTitleTooltip = t("myContent.sortBy");

  const handleToggleDirection = (e: React.MouseEvent) => {
    e.stopPropagation();
    sortHandler.handleSort(sortHandler.sort.by || sortOptions[0].by);
  };

  return (
    <Fragment>
      <Tooltip title={sortByTitleTooltip}>
        <Button
          color={"inherit"}
          variant={"text"}
          onClick={handleClick}
          sx={{ textTransform: "none"}}
          endIcon={
            <ArrowDownwardIcon
              color={"action"}
              onClick={handleToggleDirection}
              style={{
                transform:
                  sortHandler.sort.direction === "asc"
                    ? "rotate(180deg)"
                    : "none",
                transition: "all 0.3s",
                fontSize: 16,
              }}
            />
          }
        >
          <Typography variant={"caption"}>
            {t(selectedSortItem.label)}
          </Typography>
        </Button>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            marginTop: "8px",
          },
        }}
      >
        <MenuList onClick={handleClose}>
          {sortOptions.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => sortHandler.handleSort(item.by)}
              selected={(sortHandler.sort.by || sortOptions[0].by) === item.by}
            >
              <Stack direction={"row"} alignItems={"center"} columnGap={0.5}>
                <Typography variant={"caption"}>{t(item.label)}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </Fragment>
  );
}

export default SortPopOver;
