import { create } from "zustand";
import { createCommonStore, Entity, useEntityList } from "./core";
import { API_URL } from "../utils/config";
import {SystemSetting, SystemSettingKey} from "../models/SystemSetting";
import {SystemSettingGroup} from "../models/SystemSettingGroup";
import {useMap} from "../hooks/common/useMap";
import {getTenantId} from "../utils/utils";

export const useSystemSettingsStore = create<Entity<SystemSetting>>(
  createCommonStore(`${API_URL}/system_settings`, "settingKey")
);

export const useSystemSettingsList = (): [SystemSetting[], boolean] => {
  const store = useSystemSettingsStore();
  return useEntityList<SystemSetting>(store);
};

export const useSystemSettingGroupStore = create<Entity<SystemSettingGroup>>(
  createCommonStore(`${API_URL}/system_setting_groups/list`, "code")
);

export const useSystemSettingsGroupList = (): [SystemSettingGroup[], boolean] => {
  const store = useSystemSettingGroupStore();
  return useEntityList(store);
};

export function useSystemSettingKey<T>(key: SystemSettingKey, defaultValue: T) {
  const [list] = useSystemSettingsList()
  const map = useMap<SystemSetting>(list, "settingKey")
  return map.get(key)?.value ?? defaultValue
}

// Public
export const usePublicSystemSettingsStore = create<Entity<SystemSetting>>(
  createCommonStore(`${API_URL}/p/system_settings?t=${getTenantId()}`, "settingKey")
);

export const usePublicSystemSettingsList = (): [SystemSetting[], boolean] => {
  const store = usePublicSystemSettingsStore();
  return useEntityList<SystemSetting>(store);
};