import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import Paging from "../../models/Pageable";
import { MediaAsset, MediaAssetType } from "../../models/MediaAsset";
import { fetchData } from "../../utils/utils";
import { API_URL } from "../../utils/config";
import axios from "axios";

const initialState = {
  label: "",
  altText: "",
  description: "",
  type: MediaAssetType.ASSET,
};

const useMediaLibrary = (open: boolean, query: string, type: MediaAssetType, openUpload: boolean) => {
  const [assetResult, setAssetResult] = useState(new Paging<MediaAsset>());
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [form, setForm] = useState(initialState);
  const [format, setFormat] = useState("")
  const [page, setPage] = useState(0)
  const [progressStatus, setProgressStatus] = useState({
    total: 100,
    uploaded: 0
  })

  const cancel = useRef(undefined)

  const handleFetchAssets = useCallback(() => {

    const config = {
      cancelToken: new axios.CancelToken(function executor(c: any) {
        cancel.current = c; // keep reference for cancellation
      }),
    };

    const endpoint = (type === MediaAssetType.ICON) ? "icons" : "assets"
    return fetchData(`${API_URL}/media_library/${endpoint}?f=${format}&page=${page}&q=${query}&sort=createdAt,desc`, config)
      .then((res) => setAssetResult(res))
      .catch((ex) => console.log(ex))
      .finally(() => setLoading(false));
  }, [type, format, page, query]);

  useEffect(() => {
    setPage(0)
  }, [format])

  useEffect(() => {
    if (openUpload) {
      setForm(initialState)
    }
  }, [openUpload]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      setForm(initialState)
    }
  }, [handleFetchAssets, open]);

  useEffect(() => {
    let timeout: any
    if (open) {
      timeout = setTimeout(() => {
        handleFetchAssets();
      }, 100)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      if (cancel.current) {
        // @ts-ignore
        cancel.current();
      }
    }
  }, [handleFetchAssets, open])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const value = e.currentTarget.value;
    setForm((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }, []);

  const handleUploadFile = useCallback(async () => {
    setUploading(true);
    const url = `${API_URL}/media_library/assets`;
    const formData = new FormData();
    formData.append("file", newFiles[0]);
    formData.append("data", new Blob([JSON.stringify({...form, type})], { type: "application/json" }));

    await axios
      .postForm(url, formData, {
        onUploadProgress: (progressEvent) => {
          setProgressStatus({
            uploaded: progressEvent.loaded,
            total: progressEvent.total,
          });
        },
      })
      .then(() => handleFetchAssets())
      .finally(() => {
        setUploading(false);
        setProgressStatus({
          uploaded: 0,
          total: 100
        })
      });

  }, [form, handleFetchAssets, newFiles, type]);


  const handleDrop = (files: File[]) => {
    if (!files || (files&&files.length===0)) return false
    setNewFiles(files);
    if (!form.label) {
      setForm((prevState) => ({
        ...prevState,
        label: files[0].name,
      }));
    }
  };

  return {
    handleDrop,
    handleUploadFile,
    handleChange,
    assetResult,
    loading,
    uploading,
    form,
    newFiles,
    handleFetchAssets,
    setFormat,
    format,
    setPage,
    page,
    progressStatus
  };
};

export default useMediaLibrary
