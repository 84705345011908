import React from 'react';
import ListOfText from "../common/ListOfText";
import { Grid } from "@mui/material";

const DateSelect = (props:any) => {
  const {field, fieldNumber} = props

  let dateSelected = new Date(field.value+'Z')

  return <>
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <ListOfText field={{...field,value:dateSelected.toLocaleDateString()}} fieldNumber={fieldNumber} />
      </Grid>
    </Grid>
  </>
}

export default DateSelect;
