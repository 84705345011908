import { useState } from 'react';
import Survey from "../models/Survey"; // Import your reorder function
import { Field } from '../components/fields/ShortAnswer';
import { SurveyType } from "../models/SurveyItem";
import moment from 'moment';
import { DropResult } from "react-beautiful-dnd";
import { reorder } from "../../../utils/utils";
import { FIELD_DEFAULTS } from '../components/consts';


interface UseSurveyProps {
  fields: Field[] | undefined;
  setFields: React.Dispatch<React.SetStateAction<Field[]>>;
  survey: Survey;
  setSurvey: React.Dispatch<React.SetStateAction<Survey>>;
  disabled: boolean;
}

export const useSurvey = (props: UseSurveyProps) => {
  const { fields, setFields, survey, setSurvey } = props;
  const [selected, setSelected] = useState<Number | string>(-1);

  const changeLabel = (e: any) => {
    const value = e.target.value;
    // @ts-ignore
    setSurvey({ ...survey, label: value });
  };

  const changeDescription = (e: any) => {
    const value = e.target.value;
    // @ts-ignore
    setSurvey({ ...survey, description: value });
  };

  const changeType = (e: any) => {
    const value = e.target.value;
    // @ts-ignore
    setSurvey({ ...survey, surveyType: value as SurveyType });
  };

  const changeMessage = (e: any) => {
    const value = e.target.value;
    // @ts-ignore
    setSurvey({ ...survey, submissionMessage: value });
  };

  const changeSectionTitle = (e: any, index: number) => {
    const value = e.target.value;
    // @ts-ignore
    const sections = [...survey.sections];
    sections[index].title = value;
    // @ts-ignore
    setSurvey({ ...survey, sections });
  };

  const changeSectionDescription = (e: any, index: number) => {
    const value = e.target.value;
    // @ts-ignore
    const sections = [...survey.sections];
    sections[index].description = value;
    // @ts-ignore
    setSurvey({ ...survey, sections });
  };

  const selectQuestion = (i: number | string) => {
    setSelected(i);
  };

  const addNew = (sectionIndex: number, questionIndex: number) => {
    const fieldId = `field-${new Date().valueOf()}`;
    // @ts-ignore
    const sections = [...survey.sections];
    // @ts-ignore
    const questions = [...survey.questions];
    const newIndex = questionIndex === 0 ? questions.length : questionIndex;
    sections[sectionIndex].questions.push(fieldId);

    // @ts-ignore
    questions.splice(newIndex, 0, {
      ...FIELD_DEFAULTS.SHORT_ANSWER,
      id: fieldId,
    });

    // @ts-ignore
    setSurvey({
      ...survey,
      sections,
      questions,
    });

    // @ts-ignore
    setFields(questions)

    setSelected(fieldId);
  };

  const addNewSection = () => {
    const sectionId = `section-${new Date().valueOf()}`;
    // @ts-ignore
    const sections = [...survey.sections];

    // @ts-ignore
    sections.push({
      id: sectionId,
      title: '',
      questions: [],
    });

    // @ts-ignore
    setSurvey({
      ...survey,
      sections,
    });

    // @ts-ignore
    setSelected(fields.length);
  };

  const duplicateField = (fieldIndex: number) => {
    // @ts-ignore
    const nFields = [...fields];
    // @ts-ignore
    nFields.splice(fieldIndex, 0, { ...nFields[fieldIndex], id: `field-${new Date().valueOf()}` });
    setFields(nFields);
  };

  const deleteField = (fieldIndex: number, sectionIndex: number, sectionFieldIndex: number) => {
    // @ts-ignore
    const nFields = [...fields];
    // @ts-ignore
    const sections = [...survey.sections];

    sections[sectionIndex].questions.splice(sectionFieldIndex, 1);
    nFields.splice(fieldIndex, 1);

    setSelected(-1);
    // @ts-ignore
    setSurvey({
      ...survey,
      // @ts-ignore
      questions: nFields,
      sections,
    });
  };

  const setField = (i: number, data: Field) => {
    // @ts-ignore
    const nFields = [...fields];
    nFields[i] = data;
    setFields(nFields);
  };

  const goUp = (e: React.MouseEvent, index: number, sectionIndex: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (sectionIndex === 0) return false;
    // @ts-ignore
    const selectedField = fields[index].id;
    const newIndex = sectionIndex - 1;
    // @ts-ignore
    setFields(reorder(fields, index, newIndex));
    // @ts-ignore
    setSelected(selectedField);
  };

  const goDown = (e: React.MouseEvent, index: number, sectionLength: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (index === sectionLength - 1) return false;
    const newIndex = index + 1;
    // @ts-ignore
    const selectedField = fields[index].id;
    // @ts-ignore
    setFields(reorder(fields, index, newIndex));
    // @ts-ignore
    setSelected(selectedField);
  };

  const goUpSection = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (index === 0) return false;
    const newIndex = index - 1;
    // @ts-ignore
    setSurvey({
      ...survey,
      // @ts-ignore
      sections: reorder(survey.sections, index, newIndex),
    });
  };

  const goDownSection = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore
    if (index === survey.sections.length) return false;
    const newIndex = index + 1;
    // @ts-ignore
    setSurvey({
      ...survey,
      // @ts-ignore
      sections: reorder(survey.sections, index, newIndex),
    });
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const fromSection = source.droppableId.split('_')[1];
      const toSection = destination.droppableId.split('_')[1];

      // @ts-ignore
      const fromSectionPos = survey.sections.findIndex(s => s.id === fromSection);
      // @ts-ignore
      const toSectionPos = survey.sections.findIndex(s => s.id === toSection);

      // @ts-ignore
      const newSections = [...survey.sections];

      // @ts-ignore
      newSections[toSectionPos].questions.push(fields[source.index].id);
      // @ts-ignore
      newSections[fromSectionPos].questions.splice(newSections[fromSectionPos].questions.findIndex(q => q === fields[source.index].id), 1);
      // @ts-ignore
      setSurvey({
        ...survey,
        sections: [...newSections],
      });
    }

    if (selected === source.index) {
      setSelected(destination.index);
    }

    if (selected === destination.index) {
      setSelected(source.index);
    }

    // @ts-ignore
    setFields(reorder(fields, source.index, destination.index));
  };

  const changeOpenAt = (datetime: any) => {
    // @ts-ignore
    setSurvey({
      ...survey,
      openAt: datetime.toISOString(),
    });
  };

  const changeCloseAt = (datetime: any) => {
    // @ts-ignore
    setSurvey({
      ...survey,
      closeAt: datetime.toISOString(),
    });
  };

  const openDays = (current: moment.Moment) => {
    // @ts-ignore
    let closeDay = moment(survey.closeAt);
    return current.isBefore(closeDay);
  };

  const closeDays = (current: moment.Moment) => {
    // @ts-ignore
    let openDay = moment(survey.openAt);
    return current.isAfter(openDay);
  };

  const createMarkup = (htmlcode: string) => {
    return { __html: htmlcode };
  };

  return {
    selected,
    changeLabel,
    changeDescription,
    changeType,
    changeMessage,
    changeSectionTitle,
    changeSectionDescription,
    selectQuestion,
    addNew,
    addNewSection,
    duplicateField,
    deleteField,
    setField,
    goUp,
    goDown,
    goUpSection,
    goDownSection,
    onDragEnd,
    changeOpenAt,
    changeCloseAt,
    openDays,
    closeDays,
    createMarkup,
  };
};
