import React, { ChangeEvent, FocusEvent } from 'react';
import { FormControl, TextField, FormLabel, FormHelperText, Typography } from "@mui/material";

interface FieldErrors {
  required: boolean;
  invalidFormat: boolean;
}

interface Field {
  title: string;
  description: string;
  index: number;
  required: boolean;
  value: string;
  errors?: FieldErrors;
}

interface EmailProps {
  field: Field;
  setField: (field: Field) => void;
  selected: boolean;
  answerable: boolean;
  formDisabled: boolean;
  standalone: boolean;
  fieldNumber: number;
}

const Email: React.FC<EmailProps> = (props) => {
  const { field, setField, selected, answerable, formDisabled, standalone, fieldNumber } = props
  const { title, description, value, required, index } = field

  const invalidEmail = (value: string): boolean => {
    if (value !== '' && value !== null) {
      // eslint-disable-next-line
      const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return !expression.test(String(value).toLowerCase())
    } else {
      return false
    }
  }

  const setValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setField({
      ...field,
      errors: {
        ...field.errors,
        required: answerable && required && value === '',
        invalidFormat: !!invalidEmail(value)
      },
      value
    })
  }

  const checkErrors = (e: FocusEvent<HTMLInputElement>) => {
    if (!answerable) return

    let errors: FieldErrors = field.errors ? { ...field.errors } : { required: false, invalidFormat: false }

    if (required) {
      if (field.value === '' || field.value === null) {
        errors.required = true
      }
    }

    errors.invalidFormat = invalidEmail(field.value)

    setField({
      ...field,
      errors
    })
  }

  const requiredError = field.errors && field.errors.required;
  const invalidFormat = field.errors && field.errors.invalidFormat;
  const hasError = requiredError || invalidFormat;

  if ((!selected) || answerable) return (
    <FormControl error={hasError} fullWidth>
      <FormLabel>{!standalone ? `${fieldNumber + 1}.` : ``} {title} {required && <b>*</b>}</FormLabel>
      <Typography variant="caption" display="block" gutterBottom>{description}</Typography>
      <TextField
        type="email"
        name={`email-${index}`}
        placeholder={'example@email.com'}
        disabled={formDisabled}
        value={value}
        onChange={setValue}
        onBlur={checkErrors}
      />
      {hasError && <FormHelperText>{requiredError ? 'Required' : ''} {invalidFormat ? 'Invalid format' : ''}</FormHelperText>}
    </FormControl>
  )

  return null;
}

export default Email;
