export enum DatastreamMimetype {
  Datastreams = "Datastreams",
  Image = "Image",
  PDF = "PDF",
  Text = "Text",
  Word = "Word",
  Presentation = "Presentation",
  Excel = "Excel",
  Open = "Open Office",
  Video = "Video",
  Audio = "Audio",
  Archive = "Archive",
  ThreeD = "3D",
}
