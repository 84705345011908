import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";

export const filters = [
  {
    name: "toolbar.searchbar.container",
    type: "CONTAINER",
    Icon: FolderOpenOutlinedIcon,
  },
  {
    name: "toolbar.searchbar.datastream",
    type: "DATASTREAM",
    Icon: InsertDriveFileOutlinedIcon,
  },
  {
    name: "toolbar.searchbar.image",
    type: "IMAGE",
    Icon: ImageOutlinedIcon,
  },
  {
    name: "toolbar.searchbar.pdf",
    type: "PDF",
    Icon: PictureAsPdfOutlinedIcon,
  },
  {
    name: "toolbar.searchbar.video",
    type: "VIDEO",
    Icon: OndemandVideoOutlinedIcon,
  },
];
