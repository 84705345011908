import React from "react";
import CommonCard from "../../../../components/CommonCard";
import { Chip, CardContent, Stack, Typography } from "@mui/material";
import SurveyItem, { SURVEY_TYPES_LIST } from "../../models/SurveyItem";
import moment from "moment";

type SurveyItemCardProps = {
  item: SurveyItem
};

function SurveyItemCard(props: SurveyItemCardProps) {
  const { item } = props;
  const {label, description,uuid, locked, closeAt, openAt, surveyType} = item;

  const closeDate = new Date(closeAt+'Z');
  const openDate = new Date(openAt+'Z');
  const nowDate = new Date();
  const dateExpired = !(closeDate > nowDate);
  const isOpen = !locked && !dateExpired;
  const willOpen = (openDate>nowDate);
  const willClose = (closeDate>nowDate);

  const renderHeader = () => {
    return (
      <CardContent sx={{ p:2 }} key={uuid}>

        <Stack direction="row" spacing={1} sx={{ minWidth: 0 }}>

        {isOpen && (<>
            <Chip size="small" label="OPEN" color="success" /> {' '}
            <Chip size="small" variant='outlined' label={surveyType && SURVEY_TYPES_LIST.find((s) => s.value === surveyType)?.label} color="info" />
          </>
        )}

        {!isOpen && (
          <>
            <Chip size="small" label="CLOSED" color="error" /> {' '}
            {dateExpired && !locked && <Chip size="small" label="Expired" color="warning" />}
            {locked && <Chip size="small" label="Locked" color="warning" />} {' '}
            <Chip size="small" variant='outlined' label={surveyType && SURVEY_TYPES_LIST.find((s) => s.value === surveyType)?.label} color="info" />
          </>
        )}

        </Stack>
      </CardContent>
    );
  };

  const renderFooter = () => {

    return <Stack gap={2} pt={2}>

      {willOpen && (
        <>
          <Typography variant={'subtitle2'}>Opens at {moment(openDate).format("DD/MM/YYYY, h:mm a")}</Typography>
        </>
      )}
      {willClose && (
        <>
          <Typography variant={'subtitle2'}>Closes at {moment(closeDate).format("DD/MM/YYYY, h:mm a")}</Typography>
        </>
      )}

    </Stack>
  }

  return (
    <CommonCard
      title={label}
      description={description}
      renderHeader={renderHeader()}
      renderFooter={renderFooter()}
      cardHeight={"180px"}
    />
  );
}

export default SurveyItemCard;
