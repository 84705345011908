import React, { useState } from 'react';
import { Typography, Box, Button, List, ListItem, ListItemText } from '@mui/material';

interface Field {
  questionTitle: string;
  answerCount: number;
  answerSummary: string[];
}

interface ListOfTextProps {
  field: Field;
  fieldNumber: number;
}

const ListOfText: React.FC<ListOfTextProps> = ({ field, fieldNumber }) => {
  const { questionTitle, answerCount, answerSummary } = field;
  const [limit, setLimit] = useState(10);

  const showMore = () => {
    setLimit(limit + 20);
  }

  const answersLeft = answerSummary.length - limit;

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
    {fieldNumber + 1}. {questionTitle}
  </Typography>
      <Typography variant="body1" mb={1}>
        {answerCount} responses
      </Typography>

  <List>
  {answerSummary.slice(0, limit).map((answer, i) => (
      <ListItem key={i}>
      <ListItemText
        primaryTypographyProps={{ variant: 'body1' }}
  primary={answer}
  />
  </ListItem>
))}
  </List>

  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
  {(answerSummary.length > 10 && answersLeft > 0) && (
    <Button onClick={showMore}>View more ({answersLeft})</Button>
  )}
  </Box>
  </Box>
);
}

export default ListOfText;
