import { create } from "zustand";
import { Profile, User } from "../models/User";
import {createCommonStore, Entity, useEntityList} from "./core";
import { API_URL } from "../utils/config";
import {useCallback} from "react";
import IAMPermission from "../models/IAMPermission";
import {fetchData} from "../utils/utils";

interface StoreState {
  profile: Profile;
  setProfile: (profile: Profile) => void;
  getProfile: () => void
}

export const useProfileStore = create<StoreState>((set) => ({
  profile: {
    uuid: "",
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    iamPermissions: []
  },
  getProfile: async () => {
    fetchData(API_URL + `/my/profile`)
      .then((profile: Profile) => set({ profile }))
      .catch((ex) => console.log(ex));
  },
  setProfile: (profile) =>
    set((state) => ({
      ...state,
      profile,
    })),
}));

export const useUserStore = create<Entity<User>>(createCommonStore(`${API_URL}/users`, "uuid"));

export const useUserList = (): [User[], boolean] => {
  const store = useUserStore()
  return useEntityList(store)
};


export const useIAMPermission = () => {
  const { profile } = useProfileStore()

  const has = useCallback((permission: IAMPermission) => {
    return profile.iamPermissions.includes(permission)
  }, [profile])

  const hasSome = useCallback((permissions: IAMPermission[]) => {
    return permissions.some((r) => profile.iamPermissions.includes(r));
  }, [profile])

  return {
    has,
    hasSome
  }
}
