import React, { ChangeEvent, useRef, useState } from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { bytesToSize } from "../../utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadImage from "./file-upload.image";
import { useTranslation } from "react-i18next";
import { ImageDimensions } from "../../models/Import";
import { useSnackbar } from "notistack";
import ReservedUUID from "../../models/ReservedUUID";
const mime = require("mime");
type Props = {
  updateFilesCb: (files: File[]) => void;
  maxFileSizeInBytes?: number;
  message?: string;
  acceptMessage?: string;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  imageDimensionsReq?: ImageDimensions;
  allowedMimetypes?: string[];
};

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 200 * 1048576;

const convertNestedObjectToArray = (nestedObj: { [x: string]: any }) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const FileUploadComponent = ({
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  message,
  acceptMessage,
  accept = "",
  multiple = false,
  disabled,
  imageDimensionsReq,
  allowedMimetypes,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fileInputField = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<{ [key: string]: File }>({});

  const handleUploadBtnClick = () => {
    if (fileInputField.current) fileInputField.current.click();
  };

  const addNewFiles = async (newFiles: FileList) => {
    let updatedFiles: { [key: string]: File } = { ...files };

    for (let file of Array.from(newFiles)) {
      const extension = file.name.split(".").pop();
      const type = file.type;
      if (accept) {
        if (extension ? accept.includes(extension) : false) {
          if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
            enqueueSnackbar(
              `${file.name} not imported. File size exceeds max size requirements.`,
              { variant: "warning" }
            );
            continue;
          }
          // If imageDimensionsReq is provided, check if the file meets the requirement
          if (imageDimensionsReq) {
            const bitmap = await createImageBitmap(file);
            if (
              bitmap.width < imageDimensionsReq.width ||
              bitmap.height < imageDimensionsReq.height
            ) {
              enqueueSnackbar(
                `${file.name} not imported. Does not meet dimension requirements.`,
                { variant: "warning" }
              );
              continue;
            }
          }

          if (!multiple) {
            return { [file.name]: file };
          }
          updatedFiles[file.name] = file;
        }
      } else {
        if (allowedMimetypes) {
          if (allowedMimetypes.length === 0) {
            enqueueSnackbar(
              `${file.name} not imported. File mimetype is not allowed.`,
              { variant: "warning" }
            );
            continue;
          }
          if (
            allowedMimetypes.length > 0 &&
            allowedMimetypes[0] !== ReservedUUID.ContentModelAllowedAll
          ) {
            if (type) {
              if (!allowedMimetypes.includes(type)) {
                enqueueSnackbar(
                  `${file.name} not imported. File mimetype is not allowed.`,
                  { variant: "warning" }
                );
                continue;
              }
            } else {
              const mimetype = mime.getType(extension);
              if (!mimetype || !allowedMimetypes.includes(mimetype)) {
                enqueueSnackbar(
                  `${file.name} not imported. File mimetype is not allowed.`,
                  { variant: "warning" }
                );
                continue;
              }
            }
          }
        }
        if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
          enqueueSnackbar(
            `${file.name} not imported. File size exceeds max size requirements.`,
            { variant: "warning" }
          );
          continue;
        }
        if (imageDimensionsReq) {
          const bitmap = await createImageBitmap(file);
          if (
            bitmap.width < imageDimensionsReq.width ||
            bitmap.height < imageDimensionsReq.height
          ) {
            enqueueSnackbar(
              `${file.name} not imported. Does not meet dimension requirements.`,
              { variant: "warning" }
            );
            continue;
          }
        }
        if (!multiple) {
          return { [file.name]: file };
        }
        updatedFiles[file.name] = file;
      }
    }

    return updatedFiles;
  };

  const callUpdateFilesCb = (files: { file: any } | { file?: undefined }) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files: newFiles } = e.target;
    if (newFiles && newFiles.length) {
      let updatedFiles = await addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const removeFile = (fileName: string) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          position: "relative",
          height: 250,
          border: "2px dotted",
          borderColor: "background.active",
          borderRadius: 1,
          opacity: disabled ? 0.5 : 1,
          "&:hover": {
            backgroundColor: disabled ? "inherit" : "background.neutral",
          },
        }}
      >
        <Stack direction={"row"} columnGap={2} alignItems={"center"}>
          <IconButton onClick={handleUploadBtnClick}>
            <CloudUploadOutlinedIcon sx={{ fontSize: 60 }} />
          </IconButton>
          <Stack direction={"column"} rowGap={0.5} alignItems={"flex-start"}>
            <Typography variant={"body1"}>{message}</Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              {t("uploadComponent.uploadSizeMsg").replace(
                "{FILE_SIZE}",
                bytesToSize(maxFileSizeInBytes) ?? ""
              )}
              <br />
              {acceptMessage &&
                `${t("uploadComponent.uploadFormatsMsg")} ${acceptMessage}`}
            </Typography>
          </Stack>
        </Stack>
        <input
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            textTransform: "none",
            display: "block",
          }}
          accept={accept}
          multiple={Boolean(multiple)}
          disabled={disabled}
        />
      </Stack>
      <Stack direction={"column"} rowGap={0.5} mt={1}>
        {Object.keys(files).map((fileName, index) => {
          let file = files[fileName];
          return (
            <Card key={index}>
              <CardContent>
                <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                  <FileUploadImage file={file} />
                  <Stack
                    direction={"column"}
                    rowGap={0.5}
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "block",
                    }}
                  >
                    <Typography noWrap variant={"caption"}>
                      {file.name}
                    </Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>
                      {bytesToSize(file.size)}
                    </Typography>
                  </Stack>
                  <div style={{ flexGrow: 1 }}></div>
                  <IconButton
                    size={"small"}
                    onClick={() => removeFile(fileName)}
                  >
                    <CloseIcon fontSize={"small"} />
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          );
        })}
      </Stack>
    </Box>
  );
};

export default FileUploadComponent;
