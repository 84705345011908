import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';
import { FIELD_DEFAULTS, FIELD_TYPE, FIELD_TYPES } from '../consts';

interface FieldHeaderSelectedProps {
  field: any;
  setField: (field: any) => void;
}

const FieldHeaderSelected: React.FC<FieldHeaderSelectedProps> = (props) => {
  const { field, setField } = props;
  const { title, description, type } = field;

  const changeTitle = (e: any) => {
    const value = e.target.value;
    setField({
      ...field,
      title: value
    });
  };

  // Handle defaults on change type
  const changeType = (e: any) => {
    const value = e.target.value

    // See if you need to change Title/Description
    // @ts-ignore
    const titleWasChanged = field.title !== FIELD_DEFAULTS[field.type].title
    const descriptionWasChanged = field.description !== ''

    // Allow transformation from multiple choice to checkboxes
    if ((field.type === FIELD_TYPE.MULTIPLE_CHOICE || field.type === FIELD_TYPE.CHECKBOXES) &&
      (value === FIELD_TYPE.MULTIPLE_CHOICE || value === FIELD_TYPE.CHECKBOXES)) {
      setField({
        ...field,
        // @ts-ignore
        ...FIELD_DEFAULTS[value],
        // @ts-ignore
        title: titleWasChanged ? field.title : FIELD_DEFAULTS[value].title,
        // @ts-ignore
        description: descriptionWasChanged ? field.description : FIELD_DEFAULTS[value].description,
        details: {...field.details}
      })
    } else {

      let newField = {
        ...field
      }

      // Clear any other type of values for specific types
      newField.details &&
      delete newField.details;

      newField.value &&
      delete newField.value;

      newField = {
        ...newField,
        // @ts-ignore
        ...FIELD_DEFAULTS[value],
        // @ts-ignore
        title: titleWasChanged ? field.title : FIELD_DEFAULTS[value].title,
        // @ts-ignore
        description: descriptionWasChanged ? field.description : FIELD_DEFAULTS[value].description,
      }
      setField(newField)
    }

  }

  const changeDescription = (e: any) => {
    const value = e.target.value;
    setField({
      ...field,
      description: value
    });
  };

  return (
    <Grid container spacing={2} sx={{marginBottom: 2}}>
      <Grid item xs={12} sm={8}>
        <TextField
          id="title"
          label="Title"
          placeholder="Title"
          value={title}
          onChange={changeTitle}
          autoFocus
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          id="typeSelect"
          select
          label="Type"
          value={type}
          onChange={changeType}
          fullWidth
        >
          {FIELD_TYPES.map((fieldType, i) => (
            <MenuItem value={fieldType.value} key={i}>
              {fieldType.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          id="description"
          label="Description"
          placeholder="Description"
          value={description}
          onChange={changeDescription}
          fullWidth
          multiline
          minRows={3}
        />
      </Grid>
    </Grid>
  );
};

interface FieldHeaderProps {
  field: any;
  selected: boolean;
}

const FieldHeader: React.FC<FieldHeaderProps> = (props) => {
  const { field, selected } = props;
  const { title } = field;

  if (selected) {
    // @ts-ignore
    return <FieldHeaderSelected {...props} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField disabled label={title} />
      </Grid>
    </Grid>
  );
};

export default FieldHeader;
