export class ShareLink {
  uuid: string="";
  objectType: string="";
  objectId: string="";
  access: string="ANYONE_WITH_LINK";
  accessGroup: object={};
  token: string="";
  password: string="";
  expiredAt: string="";
}

export default ShareLink