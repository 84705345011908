import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import FileUpload from "../../FileUpload/file-upload.component";
import * as React from "react";
import MediaForm from "./MediaForm";
import { ImageDimensions } from "../../../models/Import";

type MediaUploadProps = {
  uploading: boolean;
  setFiles: (files: File[]) => void;
  form: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string | undefined;
  imageDimensionsReq?: ImageDimensions;
  progressStatus: {
    total: number,
    uploaded: number
  }
};

function MediaUpload(props: MediaUploadProps) {
  const { uploading, setFiles, form, handleChange, accept, imageDimensionsReq, progressStatus } = props;
  const progress = Math.round((progressStatus.uploaded / progressStatus.total) * 100)

  return (
    <Grid container spacing={2}>
      <Grid item md={8} sm={6} xs={12}>
        <Collapse in={uploading}>
          <LinearProgress variant={"determinate"} value={progress} sx={{ mb: 1 }}/>
        </Collapse>
        <Stack style={{ width: "100%" }} mt={-2}>
          <FileUpload
            accept={accept}
            multiple={false}
            disabled={uploading}
            updateFilesCb={setFiles}
            acceptMessage={""}
            imageDimensionsReq={imageDimensionsReq}
          />
        </Stack>
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        <Card sx={{ p: 2 }}>
          <MediaForm form={form} handleChange={handleChange}/>
        </Card>
      </Grid>
    </Grid>
  );
}

export default MediaUpload;
