import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import BlankCanvas from "../../assets/images/undraw_blank_canvas_re_2hwy.svg";

export default function NoPreviewAvailable() {
  return (

      <Stack flexDirection={"column"} alignItems={"center"} rowGap={3} p={2}>
        <img
          src={BlankCanvas}
          alt="login"
          style={{ width: 250, height: 250 }}
        />
        <Typography variant={"caption"} color={"textSecondary"}>
          No preview available
        </Typography>
      </Stack>

  );
}
