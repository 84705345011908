import React, {useEffect} from "react";
import "./App.css";
import ThemeProvider from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import { useTokenValidation } from "./hooks/common/useTokenValidation";
import { CircularProgress, Stack } from "@mui/material";
import { SnackbarProvider } from "notistack";
import Routes from "./routes";
import {usePublicSystemSettingsStore} from "./store/systemSettingsStore";

const Progress = () => (
  <Stack
    style={{ width: "100%", height: "100%" }}
    alignItems={"center"}
    justifyContent={"center"}
  >
    <CircularProgress />
  </Stack>
);

function App() {
  const isValidating = useTokenValidation();

  const { getAll, isLoading } = usePublicSystemSettingsStore()

  useEffect(() => getAll(), [getAll])

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        preventDuplicate
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {(isValidating || isLoading) ? <Progress /> : <Routes />}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
