import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import * as React from "react";

type Props = {
  disabled?: boolean
  form: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function MediaForm(props: Props) {
  const {handleChange, form, disabled} = props
  return (
    <Stack rowGap={2}>
      <TextField
        required
        value={form.label}
        id={"label"}
        onChange={handleChange}
        label={"Label"}
        size={"small"}
        variant={"standard"}
        disabled={disabled}
        fullWidth
      />
      <TextField
        value={form.altText}
        id={"altText"}
        onChange={handleChange}
        label={"Alt"}
        size={"small"}
        variant={"standard"}
        disabled={disabled}
        fullWidth
      />
      <TextField
        value={form.description}
        id={"description"}
        multiline
        maxRows={4}
        onChange={handleChange}
        label={"Description"}
        size={"small"}
        variant={"standard"}
        disabled={disabled}
        fullWidth
      />
    </Stack>
  )
}

export default MediaForm