import { FullTextSearchDatastream } from "./Datastream";
import Container from "./Container";

export class GlobalSearch {
  containers: Container[] = [];
  datastreams: FullTextSearchDatastream[] = [];

  static containerToSearchResult(item: Container): SearchResult {
    return {
      uuid: item.uuid,
      label: item.label,
      filename: "",
      createdBy: {
        firstname: item.createdBy.firstname,
        lastname: item.createdBy.lastname,
      },
      createdAt: item.createdAt,
      publishedAt: item.publishedAt,
      parent: item.parent,
      typeName: item.type.name,
      objectId: "",
      thumb: false,
      thumbnailUrl: "",
      mimeType: "",
      repoxType: "",
      objectType: "",
      fullTextResult: {
        score: 0,
        highlightedFields: [],
        highlightedSize: 0,
      },
    };
  }

  static datastreamToSearchResult(
    item: FullTextSearchDatastream
  ): SearchResult {
    return {
      uuid: item.uuid,
      label: item.label,
      filename: item.bitstreamFile?.filename,
      createdBy: {
        firstname: item.createdBy.firstname,
        lastname: item.createdBy.lastname,
      },
      createdAt: item.createdAt,
      publishedAt: item.publishedAt,
      parent: item.parent,
      typeName: item.type ? item.type.name : "Shortcut",
      objectId: item.objectId,
      thumb: !!item.bitstreamFile?.thumbnails.mediumUrl,
      thumbnailUrl: item.bitstreamFile?.thumbnails.mediumUrl,
      mimeType: item.bitstreamFile?.mimeType,
      repoxType: item.repoxType,
      objectType: item.objectType,
      fullTextResult: {
        score: item.fullTextResult?.score ?? 0,
        highlightedFields: item.fullTextResult?.highlightedFields ?? [],
        highlightedSize: item.fullTextResult?.highlightedSize ?? 0,
      },
    };
  }
}

export class SearchResult {
  uuid: string = "";
  label: string = "";
  filename: string = "";
  createdBy: CreatedBy = {};
  createdAt: string = "";
  publishedAt: string = "";
  parent: string = "";
  typeName: string = "";
  objectId: string = "";
  thumb: boolean = false;
  thumbnailUrl: string = "";
  mimeType: string = "";
  repoxType: string = "";
  objectType: string = "";
  fullTextResult: FullTextResult = {};
}

type CreatedBy = {
  firstname?: string;
  lastname?: string;
};

type FullTextResult = {
  score?: number;
  highlightedFields?: string[];
  highlightedSize?: number;
};

export class propertyValueFilters {
  propertyUuid: string = "ANY";
  propertyValue:  string = "";
  logicalOperator: string = "AND";
  operator: string = "EQUALS";
}

class RequestDetails {
  location: string = "";
  value: string = "";
  caseSensitive: boolean = false;
  owner: string = "";
  type: string = "";
  fileType: string[] = [];
  containerTypes: string[] = [];
  datastreamTypes: string[] = [];
  publishStatus: string = "";
  isTrashed: boolean = false;
  fulltextSearch: boolean = false;
  propertyValueFilters: string = "";
}

export class SavedSearch {
  uuid: string = "";
  label: string = "";
  requestDetails = new RequestDetails();
  createdAt: string = "";
  updatedAt: string = "";
}