import * as React from "react";
import { useCallback } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import { fetchData, getTenantId, postData } from "../../utils/utils";
import { API_URL } from "../../utils/config";
import { Link as MuiLink } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import { useProfileStore } from "../../store/userStore";
import axios from "axios";
import { useSnackbar } from "notistack";
import LoginImage from "../../assets/images/undraw_secure_login_pdn4.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import { useTranslation } from "react-i18next";
import {
  usePublicSystemSettingsList,
  usePublicSystemSettingsStore,
} from "../../store/systemSettingsStore";
import { useMap } from "../../hooks/common/useMap";
import { SystemSettingKey } from "../../models/SystemSetting";
import { MediaAsset } from "../../models/MediaAsset";

const validationSchema = yup.object({
  username: yup.string().required("Username is required"),
  password: yup
    .string()
    .min(4, "Password should be of minimum 4 characters length")
    .required("Password is required"),
});

export function Copyright() {
  const { list } = usePublicSystemSettingsStore();
  const keyMap = useMap(list, "settingKey");

  return (
    <Stack
      direction={"row"}
      columnGap={1}
      justifyContent={"center"}
      sx={{ mt: 8 }}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
      </Typography>
      <MuiLink
        href={
          (keyMap.get(SystemSettingKey.branding_copyrightLink)
            ?.value as string) ?? "https://datawise.ai/"
        }
        target={"_blank"}
        style={{ textDecoration: "none" }}
      >
        <Typography variant="body2" color="primary">
          {" "}
          {keyMap.get(SystemSettingKey.branding_copyrightText)?.value ??
            "datawise.ai"}{" "}
        </Typography>
      </MuiLink>{" "}
      <Typography variant="body2" color="text.secondary">
        {" "}
        {new Date().getFullYear()}
      </Typography>
    </Stack>
  );
}

const Login = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const profileStore = useProfileStore();
  const { enqueueSnackbar } = useSnackbar();

  const [systemSettings] = usePublicSystemSettingsList();
  const keyMap = useMap(systemSettings, "settingKey");

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        username: values.username,
        password: values.password,
      };
      setSubmitting(true);
      try {
        const tokenResult = await postData(API_URL + `/auth/jwt`, payload);
        localStorage.setItem(`${getTenantId()}_token`, tokenResult.jwtToken);

        axios.defaults.headers.common = {
          Authorization: `Bearer ${tokenResult.jwtToken}`,
          "X-TenantID": getTenantId(),
        };
        const profileResult = await fetchData(API_URL + `/my/profile`);

        profileStore.setProfile(profileResult);

        navigate("/");
        enqueueSnackbar(t("notification.logIn"), {
          variant: "success",
        });
      } catch (ex) {
        enqueueSnackbar("Wrong username or password. Try again!", {
          variant: "error",
        });
      }
      setSubmitting(false);
    },
  });

  const getValue = useCallback(
    (key: SystemSettingKey): string => {
      return keyMap.get(key)?.value as string;
    },
    [keyMap]
  );

  const logoID = getValue(SystemSettingKey.branding_logoImage);
  const welcomeImageID = getValue(SystemSettingKey.branding_welcomeImage);
  const bannerImageID = getValue(SystemSettingKey.branding_bannerImage);
  const welcomeTagline = getValue(SystemSettingKey.branding_welcomeTagline)

  return (
    <Grid container>
      <img
        src={
          logoID
            ? MediaAsset.getMediaUrl(logoID as string)
            : require("../../assets/images/store-transparent.png")
        }
        style={{
          position: "absolute",
          width: "80px",
          marginTop: 40,
          marginLeft: 40,
        }}
        alt={"Logo"}
      />
      <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
        <Stack
          flexDirection={"column"}
          gap={1}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100vh"}
        >
          <Typography variant={"h4"} color={"primary.main"} sx={{ textAlign: "center" }}>
            {getValue(SystemSettingKey.branding_welcomeText) ?? t("login.welcome").toString()}
          </Typography>
          {welcomeTagline && <Typography variant={"body1"} color={"textSecondary"}>
            {welcomeTagline}
          </Typography>}
          <img
            src={
              welcomeImageID
                ? MediaAsset.getMediaUrl(welcomeImageID as string)
                : LoginImage
            }
            alt="login"
            style={{ width: 600, paddingTop: 20 }}
          />
        </Stack>
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <Container
          maxWidth={"lg"}
          sx={{ backgroundColor: "background.paper", boxShadow: 8 }}
        >
          <Stack
            flexDirection={"column"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
            height={"100vh"}
          >
            <Typography variant={"h6"} color={"primary.main"}>
              {getValue(SystemSettingKey.branding_loginText) ??
                t("login.title").toString()}
            </Typography>
            <form onSubmit={formik.handleSubmit} style={{ width: "80%" }}>
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                id="username"
                name="username"
                label={t("login.username").toString()}
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={
                  formik.touched.username &&
                  formik.errors.username && (
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      sx={{ color: "red", mt: 0, pt: 0 }}
                    >
                      {formik.errors.username}
                    </Typography>
                  )
                }
              />

              <TextField
                sx={{ mt: 2 }}
                fullWidth
                id="password"
                name="password"
                label={t("login.password").toString()}
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={
                  formik.touched.password &&
                  formik.errors.password && (
                    <Typography
                      component={"span"}
                      variant={"body2"}
                      sx={{ color: "red", mt: 0, pt: 0 }}
                    >
                      {formik.errors.password}
                    </Typography>
                  )
                }
              />
              <LoadingButton
                loading={formik.isSubmitting}
                sx={{ mt: 2 }}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                loadingPosition="end"
                endIcon={<LoginIcon />}
              >
                {t("login.login").toString()}
              </LoadingButton>
            </form>
            <Link
              to={"/forgot-password"}
              style={{ marginTop: 30, textDecoration: "none" }}
            >
              <Typography variant={"caption"} color={"primary"}>
                {t("login.forgotMsg").toString()}
              </Typography>
            </Link>
            {bannerImageID && (
              <img
                src={MediaAsset.getMediaUrl(bannerImageID as string)}
                width={200}
                height={200}
                style={{ objectFit: "contain" }}
                alt={"Logo"}
              />
            )}
            <Copyright />
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
