import {
  Box,
  ButtonGroup,
  Card,
  Container,
  FormLabel,
  Grid,
  Select,
  TextField,
  Typography,
  CardContent, Button, FormGroup, MenuItem
} from "@mui/material";
import React from "react";
import { useSurvey } from "../hooks/useSurvey";
import AddNewField from "./AddNewField";
import {
  DragDropContext,
  Draggable,
  Droppable,
} from "react-beautiful-dnd";
import { Field } from "./fields/ShortAnswer";
import Survey, { Section } from "../models/Survey";
import { SURVEY_TYPES_LIST } from "../models/SurveyItem";
import FieldRenderer from "./FieldRenderer";
import { SURVEY_TYPES } from "./consts";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import EditOverlay from "./common/EditOverlay";
import AddNewSection from "./AddNewSection";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const selectedColor = "#556ee6";
const SelectedIndicator = () => <div style={
  {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: 8,
    backgroundColor: `${selectedColor}`,
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px"
  }
}>
</div>;

interface FormProps {
  fields: Field[] | undefined;
  setFields: React.Dispatch<React.SetStateAction<Field[]>>;
  survey: Survey;
  setSurvey: React.Dispatch<React.SetStateAction<Survey>>;
  disabled: boolean;
}

const Form = (props: FormProps) => {

  const { fields, setFields, survey, setSurvey, disabled } = props;

  const {
    changeLabel, selected, selectQuestion, changeType,
    changeDescription, changeOpenAt,
    changeMessage, changeCloseAt,
    onDragEnd, goUpSection, goDownSection,
    changeSectionTitle, changeSectionDescription, goUp, goDown, setField, duplicateField,
    deleteField, addNew, addNewSection, createMarkup
  } = useSurvey({ survey, setSurvey, fields, setFields, disabled: false });


  const { label, description, surveyType, openAt, closeAt, submissionMessage } = survey;

  return <>
    <Container maxWidth={"lg"}>

      <Grid item xs={12} md={10} style={{ position: "relative", marginBottom: 15, overflow: "hidden" }}>
        {(selected !== -1) && <EditOverlay handleClick={() => selectQuestion(-1)} />}

        <Card>
          {selected === -1 && <SelectedIndicator />}

          <CardContent style={{ textAlign: "left" }}>

            {selected === -1 && <Grid container item xs={12} spacing={2}>

              <Grid item xs={12} sm={8}>
                <FormGroup>
                  <FormLabel htmlFor="label">Survey name*</FormLabel>
                  <TextField id="label" name="label" placeholder="Untitled survey" value={label} onChange={changeLabel}
                             autoFocus={true} />
                  <Typography variant="body2" style={{ marginTop: 4, opacity: 0.8 }}>This will be shown at the top of
                    the survey</Typography>
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormGroup>
                  <FormLabel htmlFor={`dropdown-list-form-type`}> Survey type </FormLabel>
                  <Select name={`dropdown-list-form-type`} id={`dropdown-list-form-type`} value={surveyType}
                          disabled={disabled} onChange={changeType}>
                    {SURVEY_TYPES_LIST.map((option: {
                      value: string | number | readonly string[] | undefined;
                      label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
                    }, i: React.Key | null | undefined) => <MenuItem value={option.value}
                                                                     key={i}>{option.label}</MenuItem>)}
                  </Select>
                  <Typography variant="body2" style={{ opacity: 0.8, marginTop: 4 }}>
                    {surveyType === SURVEY_TYPES.ANONYMOUS && "Allow anyone to respond"}
                    {surveyType === SURVEY_TYPES.EMAIL_ONLY && "Require an e-mail to respond"}
                    {surveyType === SURVEY_TYPES.USER_DETAILS && "Require personal info to respond"}
                    {surveyType === SURVEY_TYPES.SIGNED_URL && "Allow anyone with a link to respond"}
                  </Typography>
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormLabel htmlFor={"description"}> Description </FormLabel>
                  <TextField multiline minRows={3} type="textarea" id="description" name={"description"}
                             placeholder="Description" value={description} onChange={changeDescription} />
                </FormGroup>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>

              <Grid item xs={12} sm={3}>
                    <FormGroup>
                      <FormLabel htmlFor={'date-openAt'}> Open At </FormLabel>
                    <DateTimePicker
                      // id={'date-openAt'}
                      value={new Date(openAt)}
                      onChange={changeOpenAt}
                      // isValidDate={Boolean(openDays)}
                      renderInput={(params) => <TextField {...params} />}
                    />

                    </FormGroup>
                <FormGroup>
                  <FormLabel htmlFor={`date-openFrom`}> Close date </FormLabel>
                  <DateTimePicker
                    // id={'date-openFrom'}
                    value={new Date(closeAt)}
                    onChange={changeCloseAt}
                    // isValidDate={Boolean(openDays)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {/*<DateTimePicker value={new Date(closeAt)} onChange={changeCloseAt} isValidDate={closeDays} />*/}
                </FormGroup>
                  {/*<DateTimePicker value={new Date(openAt)} onChange={changeOpenAt} isValidDate={openDays}/>*/}
              </Grid>

                <Grid item xs={12} sm={9}>
                <FormGroup>
                  <FormLabel htmlFor={'submission-message'}> Submission message </FormLabel>
                  <TextField type='textarea' id='submission-message' name={'submission-message'} placeholder="Submission message" value={submissionMessage} onChange={changeMessage}/>
                </FormGroup>
              </Grid>

                <Grid item xs={12} sm={3}>

              </Grid>
              </LocalizationProvider>

            </Grid>}

            {selected !== -1 && <>
              <Typography variant="h5">{label}</Typography>
              <Typography paragraph variant="body1">{description}</Typography>
            </>}

          </CardContent>

        </Card>

      </Grid>


      <DragDropContext onDragEnd={onDragEnd}>
        {
          survey.sections && survey.sections.map((section: Section, sectionIndex: number) => {
            const sectionFields = fields?fields.filter((f) => section.questions.includes(f.id)):[]
            const isSelected = selected === section.id

            // @ts-ignore
            return <>
              <Grid item xs={12} md={10} style={{position: 'relative', overflow: 'visible'}} >

                <Card style={{marginBottom: 15, overflow: 'visible'}}>

                  {isSelected && <SelectedIndicator/>}
                  {isSelected && <Box style={{width: 80, position: 'absolute', marginLeft: -48}}>
                    <ButtonGroup variant="contained" orientation="vertical">
                      <Button style={{padding: '2px 5px'}} onClick={(e) => goUpSection(e, sectionIndex)}><KeyboardArrowUpIcon /></Button>
                      <Button style={{padding: '2px 5px'}} onClick={(e) => goDownSection(e, sectionIndex)}><KeyboardArrowDownIcon /></Button>
                    </ButtonGroup>
                  </Box>}

                  <CardContent style={{textAlign: 'left', overflow:'hidden'}}>

                    {!isSelected && <EditOverlay handleClick={()=>selectQuestion(section.id)} />}

                    {!isSelected && <CardContent>
                      <Typography variant="h5">{section.title ? section.title : `Untitled Section #${sectionIndex + 1}`}</Typography>
                      {!section.title && <Typography variant="body1" style={{whiteSpace: "pre-wrap"}}><i>Click to add a title, if needed.</i></Typography>}
                      {!section.description && <Typography variant="body1" style={{whiteSpace: "pre-wrap"}}><i>Click to add a description, if needed.</i></Typography>}
                      {section.description && <Typography variant="body1" style={{whiteSpace: "pre-wrap"}}>
                        <div dangerouslySetInnerHTML={createMarkup(section.description)}/>
                      </Typography>}
                    </CardContent>}

                    {isSelected && <Grid item container spacing={2}>
                      <Grid item xs={12} sm={9}>
                        <TextField
                          id="title"
                          name="title"
                          label="Title"
                          placeholder="Title"
                          value={section.title}
                          onChange={(e) => changeSectionTitle(e, sectionIndex)}
                          autoFocus
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <TextField
                          id="description"
                          name="description"
                          label="Description"
                          placeholder="Description"
                          multiline
                          value={section.description}
                          onChange={(e) => changeSectionDescription(e, sectionIndex)}
                          autoFocus={section.title !== '' && section.description === ''}
                          fullWidth
                        />
                      </Grid>
                    </Grid>}


                  </CardContent>


                </Card>
              </Grid>

              <Droppable droppableId={`droppable_${section.id}`}>
                {(provided) => (
                  // @ts-ignore
                  <Grid item xs={12} md={10} {...provided.droppableProps} ref={provided.innerRef}>
                    {sectionFields && sectionFields.map((field: Field, sectionFieldIndex: number) => {
                      const isSelected = selected === field.id;
                      const index = fields?fields.findIndex((f) => f.id === field.id):0;
                      const questionsSoFar = survey.sections.slice(0, sectionIndex).reduce((total: number = 0, s: any) => total + s.questions.length, 0) || 0;
                      const lastOneSelected = isSelected && (sectionFieldIndex === sectionFields.length - 1);

                      // @ts-ignore
                      return <Draggable key={`${field.type}-${index}`} draggableId={`${field.type}-${index}`} index={index}>
                        {(provided, snapshot) => (
                          // @ts-ignore
                          <Box key={index} {...provided.draggableProps} ref={provided.innerRef}>

                            {isSelected && <Box width={80} position='absolute' marginLeft={'-48px'}>
                              <ButtonGroup variant="contained" orientation="vertical">
                                <Button style={{padding: '2px 5px'}} onClick={(e) => goUp(e, index, sectionFieldIndex)}><KeyboardArrowUpIcon /></Button>
                                <Button style={{padding: '2px 5px'}} onClick={(e) => goDown(e, index, section.questions.length)}><KeyboardArrowDownIcon /></Button>
                              </ButtonGroup>
                            </Box>}

                            <Box position='relative'>
                              <Card style={{
                                position: 'relative',
                                marginBottom: 15,
                                boxSizing: 'border-box',
                                overflow: 'hidden',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 6px -1px',
                                paddingTop: '16px'
                              }}>
                                {!isSelected && <EditOverlay handleClick={()=>selectQuestion(field.id)} />}

                                <Box p={1} {...provided.dragHandleProps} style={{color: '#5c5c5c', fontSize: 18, marginBottom: 2, textAlign: 'center', position: 'absolute', zIndex: 2, top: "-5px", left: 0, width:'100%'}}>
                                  <DragHandleIcon />
                                </Box>

                                {isSelected && <SelectedIndicator/>}

                                <CardContent>
                                  <FieldRenderer
                                    // @ts-ignore
                                    field={{...field, index}}
                                    setField={(data: Field) => setField(index, data)}
                                    selected={isSelected}
                                    answerable={false}
                                    duplicateField={() => duplicateField(index)}
                                    deleteField={() => deleteField(index, sectionIndex, sectionFieldIndex)}
                                    key={field.id}
                                    formDisabled={disabled}
                                    fieldNumber={questionsSoFar + sectionFieldIndex}
                                  />
                                </CardContent>

                              </Card>

                            </Box>

                            {isSelected && !lastOneSelected && !snapshot.isDragging && <>

                              <Box  onClick={() => addNew(sectionIndex, index)}>
                                <Box position='relative'>
                                  <Card  style={{
                                    position: 'relative',
                                    marginBottom: 15,
                                    boxSizing: 'border-box',
                                    overflow: 'hidden',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 6px -1px',
                                    cursor: "pointer"
                                  }}>

                                    <CardContent>
                                      <Box display="flex" justifyContent="center" alignItems="center">
                                        <Box display='flex'>
                                          <AddIcon />
                                        </Box>
                                        <Box display='flex'>
                                          &nbsp;ADD NEW FIELD
                                        </Box>
                                      </Box>
                                    </CardContent>

                                  </Card>
                                </Box>
                              </Box>

                            </>}

                          </Box>
                        )}
                      </Draggable>
                    })}
                    {provided.placeholder}
                  </Grid>)}
              </Droppable>

              {<Grid item xs={12} md={10}>
                <AddNewField sectionIndex={sectionIndex} questionIndex={0} addNew={addNew}/>
              </Grid>}
            </>
          })
        }
      </DragDropContext>

      <AddNewSection addNew={addNewSection}/>

    </Container>
  </>
}

export default Form;
