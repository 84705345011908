import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

const TableLoader = ({ rows = 20, height = 60, spacing = 8, rounded = false }) => {
  const items = [...Array(rows)];
  return (
    <Grid container direction={"column"}>
      {items.map((i,index) => (
        <Grid key={index} item style={{ marginTop: spacing }}>
          <Skeleton variant="rectangular" height={height} style={{ borderRadius: rounded ? 10 : 0 }}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default TableLoader;
