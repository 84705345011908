import React from 'react';
import { Box, Typography } from '@mui/material';

type TableViewProps = {
  surveyData: any,
  key: number
};

const TableView = (props:TableViewProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 250
      }}
    >
      <Typography variant="h5">Table view</Typography>
    </Box>
  );
}

export default TableView;
