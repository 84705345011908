import React from 'react';
import { Typography } from "@mui/material";

interface Field {
  title: string;
  description: string;
}

interface ShortAnswerProps {
  field: Field;
  selected: boolean;
  answerable: boolean;
}

const ShortAnswer: React.FC<ShortAnswerProps> = ({ field, selected, answerable }) => {
  const { title, description } = field;

  if ((!selected) || answerable) {
    return (
      <>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="caption" display="block" gutterBottom>{description}</Typography>
      </>
    )
  }

  return null;
}

export default ShortAnswer;
