import useDatastreamStore, { FileStatus } from "../../store/datastreamStore";
import {
 CircularProgressProps,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import {Close, ExpandLess, ExpandMore} from "@mui/icons-material";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useLocation } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {Box} from "@mui/system";

export type FileProgressHolder = {
  name: string;
  status: number;
  file: File;
  data: any;
  id: string;
  uploaded: number
  total: number
  abortController: AbortController | null
};

function FileManagerWidget() {
  const { fileProgress, retryUpload, hide, setHide } = useDatastreamStore();

  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const [hasCreateButton, setHasCreateButton] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const files = Object.keys(fileProgress);

  const fileFormatted = useMemo(() => {
    return files.map((fileName) => ({
      id: fileName,
      status: fileProgress[fileName].status,
      name: fileProgress[fileName].name,
      file: fileProgress[fileName].file,
      data: fileProgress[fileName].data,
      uploaded: fileProgress[fileName].uploadedBytes,
      total: fileProgress[fileName].totalBytes,
      abortController: fileProgress[fileName].abortController,
    }));
  }, [fileProgress, files]);

  const inProgress = useMemo(() => {
    return (
      fileFormatted.filter((x) => x.status === FileStatus.inProgress).length > 0
    );
  }, [fileFormatted]);

  useEffect(() => {
    const beforeunload = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      return (ev.returnValue = "Are you sure you want to close?");
    };

    if (inProgress) {
      window.addEventListener("beforeunload", beforeunload);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, [inProgress]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setHasCreateButton(Boolean(document.getElementById("create-btn")));
    }, 200)
  }, [location.pathname]);

  if (files.length === 0 || hide) return null;

  const completed = fileFormatted.filter(
    (x) => x.status !== FileStatus.inProgress
  );

  return (
    <Card
      elevation={10}
      style={{
        zIndex: 1,
        position: "fixed",
        bottom: 0,
        margin: 16,
        right: hasCreateButton ? 68 : 0,
        width: 400,
      }}
    >
      <CardActionArea onClick={handleExpandClick}>
        <Stack p={2} sx={{ backgroundColor: "primary.main", color: "white" }}>
          <Stack columnGap={1} flexDirection={"row"} alignItems={"center"}>
            <DriveFolderUploadIcon />
            <Typography>
              <b>Uploading files</b>
            </Typography>
            <div style={{ flex: 1 }} />
            {inProgress && <CircularProgress size={12} color={"secondary"} />}
            <Typography variant={"caption"}>
              {completed.length}/{fileFormatted.length}
            </Typography>
            {expanded ? <ExpandLess /> : <ExpandMore />}
            <IconButton onClick={() => setHide(true)} size={"small"} sx={{ color: "primary.contrastText"}}>
              <Close fontSize={"small"} />
            </IconButton>
          </Stack>
        </Stack>
      </CardActionArea>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ py: 1, px: 2, maxHeight: 300, overflowY: "auto" }}>
          <Stack rowGap={1} divider={<Divider />}>
            {fileFormatted.map((file) => (
              <LineItem file={file} onRetry={retryUpload} />
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontSize={9}
          marginLeft={-0.5}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

type LineItemProps = {
  onRetry: (file: FileProgressHolder) => void;
  file: FileProgressHolder;
};

function LineItem({ file, onRetry }: LineItemProps) {

  const progress = Math.round((file.uploaded / file.total) * 100)

  // const abort = () => {
  //   file.abortController?.abort()
  // }

  const renderThumb = () => {
    switch (file.status) {
      case FileStatus.success:
        return <CloudDoneIcon color={"success"} fontSize={"large"} />;
      case FileStatus.failed:
        return <ErrorIcon color={"error"} fontSize={"large"} />;
      case FileStatus.cancelled:
        return <RemoveCircleIcon color={"secondary"} fontSize={"large"} />;
      default:
        return <CircularProgressWithLabel variant={progress > 0 ? "determinate" : "indeterminate"} size={30} value={progress} />;
    }
  };
  const controller = file.abortController

  return (
    <Stack py={1} direction={"row"} columnGap={2} alignItems={"center"}>
      <Stack style={{ width: 36 }}>{renderThumb()}</Stack>
      <Typography noWrap variant={"body2"} style={{ flex: 1 }}>
        {file.name}
      </Typography>
      {(file.status === FileStatus.failed || file.status === FileStatus.cancelled) && (
        <IconButton onClick={() => onRetry(file)}>
          <RestartAltIcon />
        </IconButton>
      )}
      {file.status === FileStatus.inProgress && controller && (
        <IconButton onClick={() => controller?.abort()}>
          <Close />
        </IconButton>
      )}
    </Stack>
  );
}

export default FileManagerWidget;
