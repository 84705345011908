enum ProfileActionType {
  account,
  console,
  releases,
  documentation,
  settings,
  feedback,
  logout
}

export default ProfileActionType
