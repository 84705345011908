import React from 'react';
import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { FIELD_TYPE } from "../consts";

interface FieldFooterProps {
  field: any;
  setField: (field: any) => void;
  duplicateField: () => void;
  deleteField: () => void;
}

const FieldFooter: React.FC<FieldFooterProps> = (props) => {
  const { field, setField, duplicateField, deleteField } = props;
  const { required, type } = field;

  const setRequired = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Disallow changing required for type email
    if (field.type === FIELD_TYPE.EMAIL) return;

    const value = e.target.checked;
    setField({ ...field, required: value });
  };

  return (
    <>
      <Grid container spacing={2} alignItems='center' justifyContent={'flex-end'} sx={{marginTop: 2}}>

        <Grid item xs={12}>
          <hr />
        </Grid>

        <Grid item >
          <Button variant="outlined" color="primary" onClick={duplicateField}>
            Duplicate
          </Button>{' '}
        </Grid>

        <Grid item>
          <Button variant="contained" color="error" onClick={deleteField}>
            Delete
          </Button>
        </Grid>

        {type !== FIELD_TYPE.LABEL && (
          <Grid item >
            <FormControlLabel
              control={
                <Switch
                  id="surveyCustomSwitch"
                  name="customSwitch"
                  checked={required}
                  onChange={setRequired}
                />
              }
              label="Required"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FieldFooter;
