import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import english from "../../assets/icons/english.png";
import landscape from "../../assets/icons/landscape.png";

/*type Tag = {
  icon: React.ComponentType<SvgIconProps>;
  name: string;
};*/
const Tag = [
  {
    name: "English",
    icon: english,
  },
  {
    name: "Landscape",
    icon: landscape,
  },
];

type CommonCardProps = {
  cardHeight?: string;
  title: string;
  code?: number;
  description?: string;
  tags?: boolean;
  renderHeader: JSX.Element; // arrow function
  renderFooter?: JSX.Element;
};
const fonts = {
  fontWeight: 400,
  fontSize: 12,
  lineHeight: 16 / 12,
  height: "28px",
  color: "custom.contrastText",
  backgroundColor: "custom.main",
  borderRadius: "8px",
};

function CommonCard(props: CommonCardProps) {
  const { title, description, tags, renderHeader, cardHeight, renderFooter, code } = props;
  return (
    <Card
      variant={"outlined"}
      sx={{ height: cardHeight }}
      className={tags ? "card-parent" : ""}
    >
      <>
        {renderHeader}
        <CardContent sx={{ pt: 0 }}>
          <Stack flexDirection={"column"} spacing={0}>
            <Typography color={"text.primary"} variant={"subtitle1"}>
              {title}
            </Typography>
            {code && <Typography variant={"subtitle2"} color={"primary"}>
              #{code}
            </Typography>}
            {description && (
              <Typography color={"text.secondary"} variant={"subtitle2"}>
                {description}
              </Typography>
            )}
          </Stack>
          {tags && (
            <Stack flexDirection={"row"} columnGap={1} mt={2}>
              {Tag.map((tag) => (
                <Chip
                  className={"card-child"}
                  key={tag.name}
                  icon={
                    <img
                      src={tag.icon}
                      alt={"img"}
                      style={{
                        paddingLeft: "10px",
                      }}
                    />
                  }
                  label={tag.name}
                  sx={fonts}
                />
              ))}
            </Stack>
          )}
          {Boolean(renderFooter)&&<>
            {renderFooter}
          </>}
        </CardContent>
      </>
    </Card>
  );
}

export default CommonCard;
