export enum SurveyType {
  ANONYMOUS = 'ANONYMOUS',
  EMAIL_ONLY = 'EMAIL_ONLY',
  USER_DETAILS = 'USER_DETAILS',
  SIGNED_URL = 'SIGNED_URL'
}

class SurveyItem {
  uuid: string = '';
  label: string = '';
  description: string = '';
  surveyType: SurveyType = SurveyType.ANONYMOUS;
  openAt: string = '';
  closeAt: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  publishedAt: string | null = null;
  publishedBy: number | null = null;
  responseCount: number = 0;
  locked: boolean = false;
  lockedMessage: boolean = false;
}

type SurveyTypeItem = {
  label: string;
  value: SurveyType;
}

export const SURVEY_TYPES_LIST: SurveyTypeItem[] = [
  {
    label: 'Anonymous',
    value: SurveyType.ANONYMOUS
  },
  {
    label: 'Email only',
    value: SurveyType.EMAIL_ONLY
  },
  {
    label: 'User details',
    value: SurveyType.USER_DETAILS
  },
  {
    label: 'Signed url',
    value: SurveyType.SIGNED_URL
  }
]

export default SurveyItem;
