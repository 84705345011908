import { FIELD_COMPONENTS } from './consts';
import React from 'react';
import { Container } from '@mui/material';
import FieldHeader from './common/FieldHeader';
import FieldFooter from './common/FieldFooter';

const FieldRenderer: React.FC<Props> = (props) => {
  const { selected, answerable, field } = props;
  const { type } = field;
  const isEditing = !answerable && selected;

  // @ts-ignore
  if (!FIELD_COMPONENTS[type]) return null;

  // @ts-ignore
  return (
    <>
      <Container style={{ textAlign: 'left', position: 'relative' }}>
        {isEditing && <FieldHeader {...props} />}
        {React.createElement(
          // @ts-ignore
          FIELD_COMPONENTS[props.field.type]
          , { ...props })}
      </Container>

      {isEditing &&
        // @ts-ignore
        <FieldFooter {...props} />
      }
    </>
  );
};

interface Props {
  selected: boolean;
  answerable: boolean;
  field: {
    type: string;
  };
}

export default FieldRenderer;
