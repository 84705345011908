import { Grid } from '@mui/material';
import React from 'react';
import ListOfText from "../common/ListOfText";

const Time = (props:any) => {
  const {field,fieldNumber} = props

  let timeSelected = new Date(field.value+'Z')

  return <>
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <ListOfText field={{...field,value:timeSelected.toLocaleDateString()}} fieldNumber={fieldNumber} />
      </Grid>
    </Grid>
  </>
}

export default Time;
