import {useState} from "react";

export const useSelectedList = (array: any, identifier = "id", multiselect = true) => {
  const [selected, setSelected] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      // @ts-ignore
      const newSelectedIds = array.map((n) => n[identifier]);
      setSelected(newSelectedIds);
      // @ts-ignore
      setSelectedRows(array.filter((i) => newSelectedIds.find((n) => n === i.uuid))
      );
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: Event, name: string) => {
    event && event.stopPropagation();
    // @ts-ignore
    const selectedIndex = selected.indexOf(name);

    if (!multiselect) {
      if (selectedIndex === -1) {
        // @ts-ignore
        setSelected([name])
      } else {
        setSelected([])
      }
      return
    }

    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      // @ts-ignore
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // @ts-ignore
    setSelectedRows(array.filter((i) => newSelected.find((n) => n === i.uuid)));
    // @ts-ignore
    setSelected(newSelected);
  };

  // @ts-ignore
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const numSelected = selected.length;

  return {
    selected,
    selectedRows,
    isSelected,
    numSelected,
    handleClick,
    setSelected,
    handleSelectAllClick,
  };
};
