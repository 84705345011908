import {useEffect, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useQuery, {updateQueryStringParameter} from "../query/useQuery";

type Sort = {
  by?: string,
  direction?: string
}

export const useSortHandlerParam = (sort: Sort) => {
  const { search, pathname } = useLocation()
  const location = useLocation()
  const navigate = useNavigate()
  const query = useQuery().get("sort")

  useEffect(() => {
    if (sort.by) {
      const value = sort.by + "," + sort.direction;
      if (value === query) return
      const newSearch = updateQueryStringParameter(search, "sort", value)
      const newSearch2 = updateQueryStringParameter(newSearch, "page", "0")
      navigate(`${pathname}/${newSearch2}`, { replace: true })
    }
  }, [location.search, sort.by, sort.direction, navigate, search, pathname, query]);
};

export const useInitialSort = () => {
  const query = useQuery().get("sort")

  return useMemo(() => {
    if (query) {
      const options = query.split(",")
      if (options[0] && options[1]) {
        return { by: options[0], direction: options[1]}
      }
    }
    return { by: "", direction: "asc" }
  }, [query])
}
